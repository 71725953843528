a.action.primary,
button {
    @include lib-css(border-radius, $button__border-radius);
}
button {
    &:active {
        @include lib-css(box-shadow, $button__shadow);
    }
}
a.action.secondary-inverted,
a.action.secondary,
a.action.primary,
a.action-primary,
a.action-secondary {
    @include lib-link-as-button();
}
.action.primary,
.action-primary {
    @include lib-button-ik-primary();
}

.action.secondary,
.action-secondary {
    @include lib-button-ik-secondary();
}

.action.secondary-inverted {
    @include lib-button-ik-secondary-inverted();
}
.page-main {
    a:not(.action) {
        @include lib-link();
        &.white{
            @include lib-link-alternative();
        };
    }
}
.page-header {
    a {
        @include lib-link-alternative();
    }
}
.page-footer {
    a {
        @include lib-link-alternative();
    }
}


