//
//  Product Lists
//  _____________________________________________


.column.main .product-items {
    margin-left: 0;
}

.product {
    &-items {
        @extend .abs-reset-list;
        display: flex;
        flex-wrap: wrap;
    }

    &-item {
        @extend .abs-add-box-sizing;
        vertical-align: top;

        .products-grid & {
            display: inline-block;
            width: 100%;
        }

        &-name {
            @extend .abs-product-link;
            display: block;
            margin: $indent__xs 0;
            word-wrap: break-word;
            hyphens: auto;
            font-size: 3rem;
            min-height: 5.8rem;
            &.name > a {
              font-weight: 700;
              color: $color-brown-grey;
              &:visited {
                color: $color-brown-grey;
              }
              &:hover {
                color: $c-text-black;
                text-decoration: none;
              }
            }
        }

        &-info {
            width: 100%;
            padding: 1.4rem 2rem 2.5rem;
            box-sizing: border-box;
            position: relative;
            max-width: 100%;

            .product-video-icon {
                float: right;
                padding: 0;
                border: 1px solid $c-table-border-grey;
                display: flex;
                align-items: center;
                position: absolute;
                right: 2rem;
                top: 2rem;
                z-index: 2;
                cursor: pointer;
                background-color: $c-background-white;
                .product-video-icon-text {
                    display: none;
                    font-size: 1.4rem;
                    font-weight: bold;
                    text-transform: uppercase;
                }

                svg {
                    height: 35px;
                    width: 35px;
                    padding: 0 .5rem;
                }
                &:hover {
                    padding-right: 2rem;
                    border: 1px solid $c-icon-black;
                    .icon-play .st1{fill-rule:evenodd;clip-rule:evenodd;fill:$c-icon-black;}
                    .product-video-icon-text {
                        display: block;
                    }
                }
            }
            .product-video-text {
                display: none;
            }
            .play-mask{
                top: 2px;
                position: relative;
            }
        }

        &-actions {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .actions-secondary {
                display: inline-block;
                & > .action {
                    @extend .abs-actions-addto-gridlist;
                    &:before {
                        margin: 0;
                    }

                    span {
                        @extend .abs-visually-hidden;
                    }
                }
                a {
                    color: $c-text-black;
                    text-decoration: underline;
                    &:hover {
                        color: $c-text-brown-grey;
                    }
                }
            }
            .actions-primary {
                display: inline-block;
            }
        }

        &-description {
            margin: $indent__m 0;
            min-height: 4rem;
        }

        .product-image-container {
            display: block;
            margin: auto;
        }

        .product-reviews-summary {
            .rating-summary {
                margin: 0 4px 0 0;
            }

            .reviews-actions {
                font-size: $font-size__s;
                margin-top: 5px;
                text-transform: lowercase;
            }
        }

        .price-box {
            margin: $indent__s 0 $indent__m;

            .price {
                font-size: 14px;
                font-weight: $font-weight__bold;
                white-space: nowrap;
            }

            .price-label {
                font-size: $font-size__s;

                &:after {
                    content: ':';
                }
            }
        }

        .special-price,
        .minimal-price {
            .price {
                font-size: 14px;
                font-weight: $font-weight__bold;
            }

            .price-wrapper {
                display: inline-block;
            }

            .price-including-tax + .price-excluding-tax {
                display: block;
            }
        }

        .special-price {
            display: block;
        }

        .old-price {
            .price {
                font-weight: $font-weight__regular;
            }
        }

        .minimal-price {
            .price-container {
                display: block;
            }
        }

        .minimal-price-link {
            margin-top: 5px;

            .price-label {
                color: $link__color;
                font-size: 14px;
            }

            .price {
                font-weight: $font-weight__regular;
            }
        }

        .minimal-price-link,
        .price-excluding-tax,
        .price-including-tax {
            display: block;
            white-space: nowrap;
        }

        .price-from,
        .price-to {
            margin: 0;
        }

        .action.tocompare {
            @include lib-icon-font-symbol($icon-compare-empty);
        }

        .tocart {
            white-space: nowrap;
        }
        &.hidden {
            display: none !important;
        }
    }
}

.page-products.page-layout-1column {
    .breadcrumbs {
        background-color: $c-background-grey;
    }
}

.column.main {
    .product {
        &-item {
            border: 1px solid $c-background-grey;
        }
    }
}

.price-container {
    .price {
        font-size: 14px;
    }

    .price-including-tax + .price-excluding-tax,
    .weee {
        margin-top: $indent__xs;
    }

    .price-including-tax + .price-excluding-tax,
    .weee,
    .price-including-tax + .price-excluding-tax .price,
    .weee .price,
    .weee + .price-excluding-tax:before,
    .weee + .price-excluding-tax .price {
        font-size: 11px;
    }

    .weee {
        &:before {
            content: '('attr(data-label) ': ';
        }

        &:after {
            content: ')';
        }

        + .price-excluding-tax {
            &:before {
                content: attr(data-label) ': ';
            }
        }
    }
}

.products-list {
    .product {
        &-item {
            display: table;
            width: 100%;

            &-info {
                display: table-row;
            }

            &-photo {
                display: table-cell;
                padding: 0 $indent__l $indent__l 0;
                vertical-align: top;
                width: 1%;
            }

            &-details {
                display: table-cell;
                vertical-align: top;
            }
        }
    }

    .product-image-wrapper {
        @extend .abs-reset-image-wrapper;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__s) {
    // .page-products .product {
    .product {
        &-item {
            table-layout: fixed;


            .product-video-icon {
                padding-right: 2rem;
                border: 1px solid $c-icon-black;
                .icon-play .st1{fill-rule:evenodd;clip-rule:evenodd;fill:$c-icon-black;}
                .product-video-icon-text {
                  display: block;
                }
            }

            &-photo {
                padding: 0 $indent__s $indent__s 0;
                width: 30%;
            }
        }
        &-item-actions {
          .actions-primary {
              .action.primary {
                  min-width: unset;
              }
          }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__s) {
    .product {
        &-item {

            &-actions {
                // display: block;

                .products-grid & {
                    margin: $indent__s 0;
                }

                .actions-primary + .actions-secondary {
                    // display: table-cell;
                    padding-left: 5px;
                    white-space: nowrap;
                    width: 50%;
                    & > * {
                        white-space: normal;
                    }
                }

                .actions-primary {
                    // display: table-cell;
                }
            }
        }
    }

    .page-layout-1column {
        .products-grid {
            .product-item {
                flex-basis: 50%;
            }
        }
    }

    .page-layout-3columns,
    .page-products.page-layout-3columns {
        .products-grid {
            .product-item {
                flex-basis: 33.3333%;
            }
        }
    }
}
@include min-screen($screen__m) {
    .products-grid {
        .product-item {
            flex-basis: 33.3333%;
            &-actions {
                .actions-primary {
                    opacity: 0;
                    transition: opacity .6s;
                }
            }
            &:hover {
                .product-item-actions {
                    .actions-primary {
                        opacity: 1;
                    }
                }
            }
        }
    }

    .page-layout-1column {
        &.page-products {
            .page-title-wrapper {
                position: absolute;
                left: 50%;
                top: 20rem;
                transform: translateX(-50%);
                text-align: center;
                color: $c-text-white;
            }
        }
    }

    .page-layout-3columns {
        .products-grid {
            .product-item {
                flex-basis: 50%;
            }
        }
    }
}
@include min-screen($screen__l) {

    .products-grid {
        .product-items {
            margin: 0;
        }
    }

    &.page-layout-1column {
        .products-grid {
            .product-item {
                flex-basis: 33.33333%;
                padding-left: 0;
            }
        }
    }
    .page-layout-1column {
        &.page-products {
            .page-title-wrapper {
                position: absolute;
                left: 50%;
                top: 30rem;
                transform: translateX(-50%);
            }
        }
    }
}
@include min-screen($screen__xl) {

    &.page-layout-1column {
        .products-grid {
            .product-item {
                flex-basis: 25%;
            }
        }
    }
    .page-products.page-layout-1column {
        .page-title-wrapper {
            position: absolute;
            left: 50%;
            top: 35rem;
            transform: translateX(-50%);
        }
    }
}
@include min-screen($screen__xxl) {

    .page-products.page-layout-1column {
        .page-title-wrapper {
            position: absolute;
            left: 50%;
            top: 40rem;
            transform: translateX(-50%);
        }
    }
}
