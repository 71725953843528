.externalproductredirect-index-index {
    background-size: cover;
    .columns .column.main {
        padding-bottom: 0;
    }
}
.product-redirect-landing {
    position: relative;
    height: 100vh;
    padding: 0 $indent__xll;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;

    @include max-screen($screen__xs){
        padding: 0 $indent__m;
    }

    &__title-img {
        text-align: center;
        padding-top: $indent__xll;
        margin-bottom: $indent__xll;
        img {
            @include max-screen($screen__m) {
                max-width: 127px;
            }
        }
    }

    &__cswrapper {
        max-width: 410px;
        width: 100%;
        box-sizing: border-box;
        margin: auto;
        padding-bottom: 150px;
    }
}
.cswrapper {
    &__form {
        margin-top: $indent__ml;
    }
    &__select-text {
        color: $c-text-white;
    }
    .action.submit {
        width: 100%;
        max-width: 100%;
        margin-top: $indent__l;
    }
}
.form {
    &__wrapper {
        margin-top: $indent__ml;
        position: relative;
    }
    &__country-wrapper {
        //Temporaly disabled
        display: none;
    }
}

.hidden {
    display: none !important;
}

.auto-redirect-popup {
    display: flex;
    visibility: hidden;
    z-index: 9;
    position: absolute;
    top: 50%;
    left: 50%;
    height: 100vh;
    width: 100vw;
    box-sizing: border-box;
    transform: translate(-50%, -50%);
    color: black;
    flex-direction: column;
    justify-content: center;
    padding: $indent__xll;
    background-color: rgba(0,0,0,0.5);
    &__message {
        padding: $indent__xll;
        background-color: $c-text-white;
        max-width: 410px;
        margin: auto;
    }
}

//Without country selector styles
.product-redirect-landing__cswrapper.cswrapper {
    max-width: 100%;
    text-align: center;
}
.cswrapper {
    &__link {
        margin-bottom: $indent__ml;
        margin-right: $indent__s2;
    }
}

.message {
    &__text {
        display: block;
        &:not(:last-child) {
            margin-bottom: $indent__ml;
        }
    }
}
