.breadcrumbs {
    @include lib-breadcrumbs();
    z-index: 5;
    ul.items{
        font-size: $font-size__s;
        & > li{
            a,
            strong,
            &:after {
                color: $c-text-black;
            }
            strong{
                font-weight: bold;
            }
        }
    }
}

.breadcrumbs {
    width: 100%;
    box-sizing: border-box;
}
.cms-page-view,
.contact-index-index {
    .breadcrumbs {
        position: absolute;
    }
    &:not(.cms-tabla-compatibilidades-quimicas) {
        .breadcrumbs {
            ul > li a,
            ul > li strong,
            ul > li:after {
                color: $color-white;
            }
        }
    }
}

.catalog-category-view {
    .breadcrumbs {
        padding-top: $indent__ml;
        padding-bottom: $indent__ml;
    }
}

.catalog-product-view {
    .breadcrumbs {
        background-color: transparent;
        margin-top: $indent__ml;
    }
}

@include max-screen($screen__m) {
    .breadcrumbs {
        padding: 0 $indent__m;
    }
    .cms-page-view,
    .contact-index-index {
        .breadcrumbs {
            top: 9.8rem;
        }
    }
}

@include min-screen($screen__m) {
    .breadcrumbs {
        padding: 0 3.4%;
    }
}

@include screen($screen__m, $screen__lx) {
    .cms-page-view,
    .contact-index-index {
        .breadcrumbs {
            top: 14.4rem;
        }
    }
}

@include min-screen($screen__lx) {
    .cms-page-view,
    .contact-index-index {
        .breadcrumbs {
            top: 9.8rem;
        }
    }
}