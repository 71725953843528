body.contact-index-index {
    color: $color-black;
    .page-title-wrapper {
        display: none;
    }
    .page-subtitle {
        margin-top: 65px;
        h2 {
            color: $color-black;
            font-size: 30px;
            font-weight: bold;
            text-align: center;
        }
    }
    p {
        text-align: center;
        &.required-fields {
            text-align: right;
            margin: 40px auto 16px;
            font-size: 14px;
            max-width: 540px;
            color: $c-greyish-brown;
        }
    }
    .contacto-wrapper {
        max-width: 930px;
        padding: 0 24px;
        margin: 0 auto 80px;
    }
    .fieldset {
        margin-bottom: 32px;
        > .field {
            max-width: 540px;
            margin: 0 auto 16px;
            > label.label {
                width: 100%;
                text-align: left;
                font-weight: normal;
                padding-top: 0;
                &:after {
                    color: $color-black;
                    margin: 0;
                }
            }
            div.control {
                width: 100%;
            }
            &.comment {
                margin-bottom: 10px;
            }
            &.privacy-policy {
                margin-bottom: 0;
                color: $c-greyish-brown;
                font-size: 14px;
                a {
                    color: $c-greyish-brown;
                    text-decoration: underline;
                }
            }
        }
        .field-recaptcha {
            max-width: 540px;
            margin: 0 auto $indent__ml;
            a {
                text-decoration: underline;
            }
            .g-recaptcha-text-box {
                color: $c-greyish-brown;
                padding-top: $indent__s2;
                a,
                a:link,
                a:visited,
                a:active {
                    color: $c-greyish-brown;
                }
            }
        }
    }
    .actions-toolbar {
        max-width: 540px;
        margin: 0 auto !important;
    }
}

@include max-screen($screen__s) {
    body.contact-index-index {
        .page-subtitle {
            margin-top: 48px;
            h2 {
                font-size: 24px;
            }
        }
        .contacto-wrapper {
            margin: 0 auto 45px;
        }
    }
}
