.block.filter {
    // margin-bottom: $indent__xl;
    margin-bottom: 0;
    background-color: $c-background-black;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: .5rem;
    position: relative;

    .filter-title {
        display: inline-block;
        color: $c-text-white;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 3.4%;

        strong {
            font-size: 18px;
        }
    }
    .filter-content {
        display: inline-block;
        & .items {
            background-color: $c-background-black;
            border-color: $c-greyish-brown;
            color: $c-text-white;
            width: 25rem;
        }
        .filter-options-content {
            &:nth-child(4) {
                display: none;
            }
            &:nth-child(5) {
                display: none;
            }
        }
        .select-input-container .selected-input-option {
            .arrow-up {
                background-image: url(../images/icon-arrow-inverse-white-ik.svg);
            }
            .arrow-down {
                background-image: url(../images/icon-arrow-ik.svg);
            }
        }
    }
}

.block-subtitle {
    display: inline-block;
    margin-bottom: $indent__s;
}

.filter-current {
    .item {
        margin-bottom: $indent__s;
    }

    .filter-label {
        display: block;
        font-weight: $font-weight__bold;

        &:after {
            content: ':';
        }
    }

    + .filter-actions {
        margin-bottom: $indent__l;
    }
}

.filter-options-content {
    .filter-count-label {
        @extend .abs-visually-hidden;
    }
}

.filter-options {
    margin: 0;

    .filter-options-title {
        @include lib-heading(h4);
        margin: 0 0 $indent__s;
        word-break: break-all;
    }

    .filter-options-content {
        display: inline-block;
        margin: 0 0 0 1.6rem;
        position:relative;

        .item {
            margin-bottom: 3px;
        }
    }

    .count {
        @include lib-css(color, $text__color__muted);

        &:before {
            content: '(';
        }

        &:after {
            content: ')';
        }
    }
}

.items {
    @extend .abs-reset-list;
}

.filtered {
    .items {
        margin: 15px 0;
    }

    .item {
        margin-bottom: 6px;
        padding-left: 22px;
        position: relative;

        .label {
            font-weight: $font-weight__bold;
        }

        .action.remove {
            @extend .abs-remove-button-for-blocks;
            left: -6px;
            position: absolute;
            top: 0;
        }
    }

    & + .actions {
        margin-bottom: 35px;
    }
}

@include max-screen($screen__lx) {
    .page-with-filter {
        &.catalog-category-view {
            .block.filter {
                .filter-title {
                    display: none;
                }
            }
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__ml) {
    .page-with-filter {
        &.catalog-category-view {
            .block.filter {
                justify-content: center;
                .filter-content {
                    display: block;
                    white-space: nowrap;
                }
                .filter-title {
                    display: none;
                }
            }
        }
    }
}
@include max-screen($screen__mml) {
    .page-with-filter.catalog-category-view {
        .block.filter {
            .filter-content {
                display: none;
                width: 100%;
                .filter-options-content {
                    display: block;
                    margin: .6rem 0;
                    &:last-child {
                        margin-bottom: 2rem;
                    }
                }
            }
            .filter-title {
                display: block;
                position: relative;
                padding: 1rem 6rem 1rem 1rem;
                cursor: pointer;
                transform: inherit;
                left: 0;
                &:after {
                    content: url(../images/icon-arrow-ik.svg);
                    position: absolute;
                    display: inline-block;
                    right: 1rem;
                    top: 50%;
                    -webkit-transform: translateY(-50%);
                    transform: translateY(-50%);
                    width: 16px;
                    transition: transform 0.5s ease-in;
                }
            }
        }
    }
}

@include max-screen($screen__mml) {
    .page-with-filter {
        .columns {
            .sidebar-main {
                @include lib-vendor-prefix-order(0);
            }
        }
        &.catalog-category-view {
            .block.filter {
                justify-content: center;
                flex-direction: column;
                padding: .5rem 2rem;
                .filter-options {
                    display: inline-flex;
                    flex-direction: column;
                    width: 100%;
                    padding: 0 $indent__ml;
                    box-sizing: border-box;
                }
                .filter-content {
                    z-index: 99;
                    .items {
                        width: 100%;
                    }
                    &.selected {
                        overflow: visible;
                    }
                }
                &.active {
                    .filter-title:after {
                        transform: translateY(-50%) rotate(-180deg);
                        transition: transform 0.5s ease-in;
                    }
                }
            }
        }
    }
}

//
// Current active filters
// ---------------------------------------------

@include max-screen($screen__mml) {
    .page-with-filter {

    }
}

@include min-screen($screen__mml) {
    .page-with-filter {

    }
}
