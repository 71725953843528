.searcheo-category-bottom {
    //max-width: 2000px;
    margin-left: 2.4rem;
    margin-right: 2.4rem;
    margin-top: $indent__m;
    h1,h2,h3,p,span {
        color: $color-black;
    }
}
.searcheo-product-block {
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
    h1,h2,h3 {
        color: $color-black
    }
    p{
        color: $color-black;
        padding-left: 2rem;
        padding-right: 2rem;
    }
}
