body.sobre-nosotros {
    .image-container {
        position: relative;
        /*.img img {
            max-width: 100%;
            min-height: 460px;
        }*/
        .page-title-wrapper {
            top: 50%;
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            position: absolute;
            left: 50%;
            color: $color-white;
        }
    }
    .page-subtitle h2 {
        color: #000;
        font-size: 30px;
        font-weight: bold;
        text-align: center;
        margin: 0;
    }
    .iksprayers-wrapper,
    .nuestra-marca-wrapper,
    .unete-wrapper,
    .mapamundi-wrapper,
    .seleccion-distribuidor-wrapper {
        p {
            text-align: center;
            color: $color-black;
        }
    }
    .iksprayers-wrapper,
    .unete-wrapper {
        max-width: 930px;
        padding: 0 24px;
        margin: 65px auto 80px;
        h3 {
            color: $c-text-brown-grey;
            font-size: 20px;
            font-weight: normal;
            margin: 0;
            text-align: center;
        }
        .page-subtitle {
            margin: 25px 0;
        }
    }
    .nuestra-marca-wrapper {
        overflow: auto;
        max-width: 2000px;
        margin: 0 auto;
        ul {
            padding: 0;
            list-style: none;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            background-color: $color-gray95;
            li {
                width: 50%;
                margin: 0;
                box-sizing: border-box;
                &.imagen {
                    font-size: 0;
                }
                &.texto {
                    padding: 0 8%;
                }
                h2 {
                    font-weight: bold;
                    font-size: 30px;
                    color: $color-black;
                }
                p {
                    text-align: left;
                }
            }
        }
    }
    .unete-wrapper {
        margin: 65px auto;
    }
    .selector-dist-wrapper {
        max-width: 540px;
        margin: 40px auto 70px;
        .label {
            color: $color-black;
            display: block;
            margin-bottom: 2px;
        }
        select {
            border: 1px solid $color-black;
            background: url('../images/icon-arrow-ik-gris.svg') no-repeat;
            background-size: 14px;
            background-position: right 16px center;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            padding: 0 40px 0 16px;
            color: $c-text-brown-grey;
            option:not(:disabled) {
                color: $color-black;
            }
        }
    }
    .distribuidores {
        .continente {
            margin-bottom: 20px;
            .label {
                font-size: 20px;
                font-weight: bold;
                color: $c-text-brown-grey;
                text-align: center;
                display: block;
                text-transform: uppercase;
                margin-bottom: 18px;
            }
            ul {
                list-style: none;
                padding: 0;
                margin: 0;
                display: flex;
                flex-wrap: wrap;
                li {
                    height: auto;
                    box-sizing: border-box;
                    border: 1px solid $c-text-brown-grey;
                    padding: 30px 24px;
                    width: calc(33.333% - 2.174%);
                    margin-right: 3.261%;
                    margin-bottom: 4.53%;
                    &:nth-child(3n) {
                        margin-right: 0;
                    }
                    &.match {
                        border: 2px solid $c-highlight-blue;
                    }
                }
            }
            h3 {
                color: $c-highlight-blue;
                margin: 0 0 8px;
                font-size: 20px;
            }
            .telefono,
            .email {
                display: block;
                color: $color-black;
                font-size: 20px;
            }
            .email {
                text-decoration: underline;
            }
            .direccion {
                margin-top: 38px;
                display: block;
                .title {
                    color: $c-text-brown-grey;
                    font-size: 20px;
                }
                span {
                    display: block;
                    font-size: 20px;
                }
            }
        }
    }
    .seleccion-distribuidor-wrapper {
        padding: 0 24px;
        max-width: 1104px;
        margin: 0 auto;
    }
    .contacta-con-nosotros-wrapper {
        text-align: center;
        margin-bottom: 7.247%;
    }
    .mapamundi-wrapper {
        text-align: center;
    }
}

@include max-screen(1200px) {
    body.sobre-nosotros .nuestra-marca-wrapper ul li.texto {
        padding: 0 4%;
    }
}

@include max-screen($screen__l) {
    body.sobre-nosotros .nuestra-marca-wrapper ul {
        margin-bottom: 0;
        li {
            width: 100%;
            position: relative;
            img {
                width: 100%;
                position: absolute;
                left: 50%;
                top: 50%;
                -webkit-transform: translate(-50%,-50%);
                -ms-transform: translate(-50%,-50%);
                transform: translate(-50%,-50%);
            }
            &.imagen {
                height: 400px;
                overflow: hidden;
            }
            &:nth-child(1) {
                order: 1;
            }
            &:nth-child(2) {
                order: 1;
            }
            &:nth-child(3) {
                order: 4;
            }
            &:nth-child(4) {
                order: 3;
            }
            h2 {
                margin-top: 0;
            }
            &.texto {
                padding: 6% 4%;
            }
        }
    }
}

@include max-screen(900px) {
    body.sobre-nosotros {
        .distribuidores .continente ul {
            li {
                width: calc(50% + 0.5px);
                margin-right: 0;
                margin-bottom: 0;
                margin-top: -1px;
                &:nth-child(2n) {
                    margin-left: -1px;
                }

            }
        }
        .contacta-con-nosotros-wrapper {
            margin-top: 50px;
        }
    }
}

@include max-screen(660px) {
    body.sobre-nosotros .distribuidores .continente ul {
        li {
            width: 100%;
            &:nth-child(2n) {
                margin-left: 0;
            }

        }
    }
}

@include max-screen($screen__s) {
    body.sobre-nosotros {
        .page-subtitle h2 {
            font-size: 24px;
        }
        .iksprayers-wrapper {
            margin: 48px auto;
            h3 {
                font-size: 18px;
            }
        }
        .unete-wrapper {
            margin: 50px auto 25px;
        }
        .nuestra-marca-wrapper ul li {
            &.imagen {
                height: 250px;
            }
            &.texto {
                padding: 35px 25px;
            }
            h2 {
                font-size: 24px;
                text-align: center;
            }
        }
    }
}

@include max-screen(390px) {
    body.sobre-nosotros .nuestra-marca-wrapper ul li {
        &.imagen {
            height: auto;
        }
        img {
            position: inherit;
            left: 0;
            top: 0;
            -webkit-transform: none;
            transform: none;
        }
    }
}