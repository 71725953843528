body.cms-alt-where-to-buy,
body[class*="where-to-buy"],
body.cms-alt-ik_where-to-buy {
    p {
        text-align: center;
        &.required-fields {
            text-align: right;
            margin: 40px auto 16px;
            font-size: 14px;
            max-width: 540px;
            color: $c-greyish-brown;
        }
    }

    .external-where-to-buy {
        text-align: center;
    }

    .where-to-buy-wrapper {
        max-width: 930px;
        padding: 0 24px;
        margin: 48px auto 80px;

        input[type="checkbox"] {
            & + label::before {
                background-image: url(../images/icon-checkbox-ik.svg);
            }
            &:checked + label::before {
                background-image: url(../images/icon-checkbox-select-ik.svg);
            }
        }

        .select-input-options{
            max-height: 400px;
        }
    }
    .fieldset {
        margin-bottom: 32px;
        > .field {
            max-width: 540px;
            margin: 0 auto 16px;
            > label.label {
                width: 100%;
                text-align: left;
                font-weight: normal;
                padding-top: 0;
                &:after {
                    color: $color-black;
                    margin: 0;
                }
            }
            div.control {
                width: 100%;
            }
            &.email {
                margin-bottom: $indent__xl;
            }
            &.comment {
                margin-bottom: 10px;
            }
            &.privacy-policy {
                margin-bottom: 0;
                color: $c-greyish-brown;
                font-size: 14px;
                a {
                    color: $c-greyish-brown;
                    text-decoration: underline;
                }
            }
        }
        .field-recaptcha {
            max-width: 540px;
            margin: 0 auto $indent__ml;
            a {
                text-decoration: underline;
            }
            .g-recaptcha-text-box {
                color: $c-greyish-brown;
                padding-top: $indent__s2;
                a,
                a:link,
                a:visited,
                a:active {
                    color: $c-greyish-brown;
                }
            }
        }
    }
    .actions-toolbar {
        max-width: 540px;
        margin: 0 auto !important;
    }
}

@include max-screen($screen__s) {
    body.cms-where-to-buy {
        .where-to-buy-wrapper {
            margin: 0 auto 45px;
        }
        .fieldset > .field.email {
            margin-bottom: 32px;
        }
    }
}
@include max-screen($screen__m) {
    body.cms-where-to-buy {
        .page-title-wrapper {
            margin: 0 auto;
        }
    }
 }
