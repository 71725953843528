.cms-tabla-compatibilidades-quimicas {
  .column.main {
    padding: 0 24px;
    box-sizing: border-box;
    border-collapse: separate;
  }

  .page-header {
    background-color: $c-background-black;
  }

  // Estilos para resetear las tablas
  th, td, tr {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    vertical-align: center;
  }

  .seccion-titulo {
    margin-top: 200px;
    margin-bottom: 40px;
    text-align: center;
  }

  .seccion-titulo h1 {
    color: $c-text-black;
    margin: 0 0 10px 0;
  }

  .seccion-titulo div {
    max-width: 897px;
    margin-left: auto;
    margin-right: auto;
  }

  .seccion-titulo p {
    font-weight: 300;
    line-height: $line-height__xs;
    color: $c-text-black;
  }

  .seccion-tabla {
    max-width: 1158px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 10px;
  }

  .tablaquimi {
    width: 100%;
    border-spacing: 0;
    border-collapse: separate;
  }

  .familias {
    padding: 14px 37px;
    text-align: center;
    width: auto;
  }

  .subtitulo {
    font-size: 16px;
    font-weight: $font-weight__bold;
    text-align: center;
    color: $c-background-black;
    vertical-align: baseline;
    margin-bottom: 5px;
    border-spacing: 10px 0;
    text-transform: uppercase;
    border-top: solid 1px $c-text-brown-grey;
  }

  .subtitulo:nth-child(1) {
    border: solid 1px $c-text-brown-grey;
    background-color: $c-background-grey;
    max-width: 259px;
    padding: 15px 10px;
  }

  .subtitulo:nth-child(2) {
    border-top: solid 1px $c-text-brown-grey;
  }

  .espacio1 {
    padding-top: 15px;
  }

  .espacio2 {
    padding-top: 43px;
  }

  .espacio3 {
    padding-top: 43px;
    border-bottom: solid 1px $c-text-brown-grey;
  }

  .productoquim {
    max-width: 259px;
    text-transform: uppercase;
    font-size: $font-size__l;
    padding: 5px 0;
  }

  .productoquim span {
    text-align: left;
  }

  td:not(:first-child) {
    text-align: center;
  }

  td:not(.subtitulo) {
    vertical-align: middle;
  }

  .tablaquimi td span {
    vertical-align: middle;
    text-align: center;
  }

  .cont_icono {
    height: 26px;
    width: 26px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .icon_apto {
    background: url("../images/icon-quimicos-apto-ik.svg") no-repeat center;
    margin-bottom: 3px;
    margin-top: 3px;
  }

  .icon_optimo {
    background: url("../images/icon-quimicos-optimo-ik.svg") no-repeat center;
    margin-bottom: 3px;
    margin-top: 3px;
  }

  .no_valido {
    background: url("../images/icon-quimicos-no-valido-ik.svg") no-repeat center;
    margin-bottom: 3px;
    margin-top: 3px;
  }

  .notas {
    margin-top: 62px;
    margin-bottom: 62px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .leyenda {
    display: flex;
    vertical-align: middle;
    text-align: center;
  }

  .leyenda span {
    display: inline-block;
    padding-right: 12px;
    padding-left: 38px;
    font-size: 16px;
    background-position: center left 5px;
    line-height: 26px;
    white-space: nowrap;
  }

  .enlaces {
    //display: flex;
    color: $c-text-black;
    vertical-align: middle;
    display: none;
  }

  .sectores {
    justify-content: flex-start;
    padding-right: 10px;
    margin-left: auto;
    margin-right: auto;
  }

  .sectores a {
    color: $c-text-black;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
  }

  .sectores a:hover {
    color: $c-text-hover;
    text-decoration: none;
  }

  .descargar {
    justify-content: flex-end;
  }

  .mobile {
    display: none;
  }

  .familia_select_wrapper {
    display: none;
  }

  .sel_modelo {
    font-weight: $font-weight__regular;
    padding-bottom: 5px;
    font-size: $font-size__base;
  }

  .visible {
    display: flex;
    position: absolute;
    visibility: visible;
    transition: visibility .1s;
    flex-direction: column;
    justify-content: center;
    background-color: $c-background-black;
    width: 100%;
    opacity: 1;
    padding: 16px 0;
    border-top: 1px solid #242424;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    cursor: pointer;
    font-weight: 700;
    color: #ffffff;
  }

  .arrow-icon {
    width: 1.4rem;
    margin-left: 10px;
  }

  .arrow-icon-visible {
    transform: rotate(-180deg);
    color: $c-text-hover;
  }

  .active {
    display: table-cell;
  }

  @include max-screen($screen__ml) {

    .familias {
      padding: 10px 10px;
    }

    .subtitulo:nth-child(1) {
      max-width: 220px;
      padding: 15px 10px;
    }

    .productoquim {
      max-width: 50%;
    }

    .lista_oculta {
      display: none;
    }

  }

  @include screen ($screen__m, $screen__lx){
    .seccion-titulo{
      margin-top: 240px;
    }
  }


  @include max-screen($screen__xxs) {
    .l-text {
      font-size: 18px;
    }
  }

  @include max-screen($screen__m) {
    .seccion-titulo{
      margin-top: 142px;
    }

    .familias {
      display: none;
    }

    .mobile {
      display: table-cell;
    }

    .familia_select {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 15px 10px;
      font-size: 16px;
      text-align: center;
      cursor: pointer;
      font-weight: 700;
      color: #ffffff;
      background-color: $c-background-black;
      border: 1px solid $c-background-black;
    }

    .familia_select:hover {
      color: #b4b4b4;

      .arrow-icon .st0 {
        fill: $c-icon-grey;
      }
    }

    .lista_wrapper {
      display: block;
      position: relative;
    }

    #lista_familias li {
      display: list-item;
      padding: 10px 0;
      margin-bottom: 0;
      list-style-type: none;
    }

    #lista_familias li.active {
      display: none;
    }

    #lista_familias li:hover {
      color: #b4b4b4;
    }

    .subtitulo:nth-child(1) {
      padding: 15px 10px;
      width: 50%;
    }
    .subtitulo:nth-child(2) {
      width: 50%;
    }

    .productoquim {
      font-size: $font-size__base;
      padding: 5px 0;
    }

    .espacio2 {
      padding-top: 20px;
    }

    .espacio3 {
      padding-top: 20px;
    }

    .leyenda {
      margin-bottom: 20px;
    }

    .notas {
      flex-direction: column;
      justify-content: space-between;
    }

    .tablaquimi td:not(.active) {
      display: none;
    }

    .tablaquimi td:first-child,
    .tablaquimi td.subtitulo,
    .tablaquimi th:first-child {
      display: table-cell;
    }


  }


}
