.cms-index-index,
.cms-home {
    .column.main {
        padding-bottom: 100px;
    }
    .video-container {
        position: relative;
        overflow: hidden;
        max-height: 880px;
        .video-gradient {
            width: 100%;
            height: 100%;
            display: block;
            position: absolute;
            z-index: 1;
            background-image: linear-gradient(180deg, rgba(0, 0, 0, .26), hsla(0, 0%, 56%, .55));
        }
        .home-video {
            max-width: none;
            min-width: 100%;
            min-height: 100%;
            display: grid;
            display: -ms-grid;
        }
        .video-text {
            display: inline-block;
            position: absolute;
            max-width: 600px;
            top: 33%;
            left: 20%;
            z-index: 2;
            .video-subtitle {
                color: $c-text-white;
                font-size: $font-size__l;
                margin-bottom: 35px;
            }
        }
    }
    .home-search {
        display: flex;
        background-color: $c-background-grey;
        .search-wrapper {
            position: relative;
            top: -50px;
            width: 95%;
            max-width: $screen__xxl;
            z-index: 2;
        }
        .search-content {
            display: flex;
            background-color: $c-greyish-brown;
        }
        .search-title {
            margin-top: 0;
        }
        .search-text {
            flex-grow: 0;
            color: $c-text-white;
            .search-subtitle {
                margin-bottom: 0;
                font-weight: $font-weight__heavier;
                color: $c-text-hover;
            }
        }
        .searcher {
            flex-grow: 2;
        }
        .search-form {
            display: flex;
            align-items: flex-end;
            label {
                color: $c-text-white;
                line-height: $line-height__base;
                padding-bottom: $indent__xs;
                display: block;
                font-weight: $font-weight__heavier;
            }
            .select-input-container .select-container {
                .selected-input-option {
                    padding: $indent__ml $indent__xxl;
                }
                .select-input-options {
                    padding: 0 $indent__m 0 $indent__ml;
                }
            }
        }

        .select-wrapper .select-icon {
            height: $indent__m;
            width: $indent__m;
            position: absolute;
            left: $indent__s;
            top: 50%;
            transform: translateY(-50%);
            background-repeat: no-repeat;
            background-size: 100%;
        }
        .sectors-wrapper .select-icon {
            background-image: url("../images/icon-sector-ik.svg");
        }
        .substance-wrapper .select-icon {
          background-image: url("../images/icon-sustancia-ik.svg");
        }
        .capacity-wrapper .select-icon {
            background-image: url("../images/icon-compatibilidades-ik.svg");
        }

    }
    .new-product-wrapper {
        background-color: $c-background-grey;
        .new-product-info-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 95%;
            margin: auto;
            max-width: $screen__xxl;
            box-sizing: border-box;
        }
        .image-wrapper {
            height: 100%;
        }
        .info-wrapper {
            width: 100%;
            box-sizing: border-box;
            .new-label {
                color: $c-notvalid-red;
                font-weight: $font-weight__bold;
            }
            .new-title {
                color: $c-text-black;
            }
            .info-text {
                margin-bottom: 0;
            }
            .action.primary {
                margin-top: $indent__l;
            }
        }
    }
    .image-and-text {
        .image-text-wrapper {
            position: relative;
            height: 600px;
        }
        .image-wrapper {
            height: 100%;
            overflow: hidden;
            picture {
                source {
                    display: none
                }
                img {
                    width: 100%;
                    object-fit: cover;
                    display: block;
                    min-height: 600px;
                    position: relative;
                    max-width: none;
                }
            }
        }
        .info-wrapper {
            position: absolute;
            max-width: 820px;
            width: 80%;
            top: 50%;
            left: 0;
            right: 0;
            margin: 0 auto;
            transform: translateY(-50%);
            color: white;
            text-align: center;
            svg {
                width: 38px;
            }
            .info-text {
                font-weight: $font-weight__regular;
            }
        }
    }
    .home-sectors {
        background-color: $c-background-white;
        margin-top: $indent__xxl;
        margin-bottom: $indent__xxl;
        .sectors-wrapper {
            display: flex;
            flex-wrap: wrap;
            max-width: 1440px;
            margin: auto;
        }
        .sector-wrapper {
            @include lib-vendor-prefix-flex-basis(33%);
            flex-grow: 1;
            position: relative;
        }
        .img-wrapper {
            img {
                display: block;
            }
            .black-mask {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: rgb(0,0,0);
                opacity: 0.5;
                transition: all .3s ease-out;
            }
            img {
                width: 100%;
            }
        }
        .info {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            color: $c-text-white;
            svg {
                width: 38px;
                max-height: 39px;
            }
            .hover-info {
                display: block;
                max-height: 0;
                opacity: 0;
                z-index: -1;
                transition: all .4s ease-in;
                .subtitle {
                    display: none;
                    margin-bottom: 8%;
                }
                .action {
                    display: none;
                }
            }
        }
        .info-wrapper {
            padding: 30px 50px;
        }
        .sector-wrapper {
            &:hover,
            &:focus,
            &.visible {
                .black-mask {
                    background-color: rgb(0, 0, 0);
                    opacity: 0.3;
                    transition: all .4s ease-in;
                }

                .info {
                    svg {
                        display: none;
                    }

                    .hover-info {
                        display: block;
                        height: auto;
                        max-height: 200px;
                        opacity: 1;
                        transition: all .4s ease-in;
                        .subtitle {
                            display: block;
                        }
                        .action {
                            display: inline-block;
                        }
                    }
                }
            }
        }
    }
    .text-button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        position: relative;
        min-height: 350px;
        margin-bottom: $indent__xxl;
        .text-button-wrapper {
            max-width: 90%;
            text-align: center;
        }
        .absolute-wrapper {
            color: $c-text-black;
            .title {
                color: $c-text-black;
            }
            .subtitle {
                font-weight: 500;
                margin-bottom: $indent__l;
            }
        }
    }
    .social-media {
        .social-block-wrapper {
            text-align: center;
            padding: 0 3.5%;
            max-width: $screen__xxl;
            margin: auto;
        }
        .block-title {
            color: $c-text-black;
        }
        .subtitle {
            max-width: 720px;
            margin: auto;
        }
        .media-wrapper {
            margin-top: $indent__xxxl;
        }
        .media-title {
            margin-bottom: $indent__ml;
            color: $c-text-hover;
            font-weight: $font-weight__bold;
        }
        .media-content {
            display: flex;
            justify-content: space-between;
            .media-item {
                display: grid;
                display: -ms-grid;
                img {
                    width: 100%;
                }
            }
        }
        .media-slider-wrapper.empty {
            display: none;
        }
        .slider-content {
            display: block;
            .instagram-slider {
                .insta-link {
                    width: 100%;
                }
                .insta-img {
                    width: 100%;
                }
                button.slick-prev,
                button.slick-prev:hover {
                    left: -20px;
                }
                button.slick-next,
                button.slick-next:hover {
                    right: -20px;
                }
            }
        }
        .media-link {
            font-weight: $font-weight__bold;
            color: $c-greyish-brown;
            margin-top: $indent__m;
            display: block;
        }
        .poster-wrapper {
            @include lib-vendor-prefix-flex-basis(32%);
            position: relative;
            cursor: pointer;
            .play-mask {
                display: block;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.4);
                opacity: 0;
                transition: all .3s ease-out;
                .icon {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    display: grid;
                    display: -ms-grid;
                    width: 48px;
                    height: 48px;
                    .icon-play {
                        width: 48px;
                        height: 48px;
                    }
                }
            }
            &:hover {
                .play-mask {
                    opacity: 1;
                    transition: all .4s ease-in;
                }
            }
        }
        /* Video ligthbox */
        .lightbox {
            display: none;
            &.active {
                display: block;
                background-color: rgba(0, 0, 0, 0.8);
                overflow: hidden;
                position: fixed;
                z-index: 9;
                bottom: 0;
                right: 0;
                left: 0;
                top: 0;

                .lightbox-container {
                    max-width: $screen__xxxl;
                    width: 75%;
                    height: 80%;
                    margin: auto;
                    display: block;
                    z-index: 10;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
                .lightbox-content {
                    height: 100%;
                }
                .video-iframe-container {
                    height: 100%;
                    iframe,
                    object,
                    embed {
                        position: relative;
                        height: 100%;
                        width: 100%;
                        left: 0;
                        top: 0;
                    }
                }
            }
        }
        /* END Video*/
        .photo-wrapper {
            @include lib-vendor-prefix-flex-basis(24%);
        }
    }
    .fairs-events-wrapper {
        background-color: $c-background-lighten-black;
        text-align: center;
        padding: 100px 8.5%;
        .block-title {
            margin-bottom: $indent__xl;
            max-width: 575px;
            display: inline-block;
            .l-text {
                color: $c-text-hover;
            }
        }
        .events {
            padding-left: 0;
            button.slick-next, button.slick-next:hover
            {
                background: url("../images/icon-arrow-slider-light-derch-ik.svg") no-repeat center;
            }
            button.slick-prev, button.slick-prev:hover {
                background: url("../images/icon-arrow-slider-light-izq-ik.svg") no-repeat center;
            }
            &.loading {
                background-image: url("../images/loader.gif") no-repeat center;
                background-size: 50px;
                width: 100%;
                height: 100px;
                > li {
                    visibility: hidden;
                }
            }
        }
        .event {
            flex-direction: column;
            align-items: flex-start;
            text-align: left;
            background-color: white;
            padding: $indent__l $indent__m $indent__m;
            box-sizing: border-box;
            .img-wrapper {
                max-width: 108px;
                height: 100%;
            }
            .texto {
                display: flex;
                flex-direction: column;
                //align-items: start;
                width: 100%;
                box-sizing: border-box;
                text-align: left;
                font-size: $font-size__l;
                line-height: $line-height__xs;
                margin: $indent__m 0;
                .title {
                    font-weight: $font-weight__bold;
                }
                .where {
                    margin-bottom: $indent__ml;
                }
            }
            .event-url {
                color: $c-greyish-brown;
                font-size: $font-size__s;
                margin-top: auto;
                position: relative;
                padding-right: $indent__s;
                &:after,
                &::after {
                    content: '>';
                    right: 0;
                    position: absolute;
                }
            }
        }
    }
}

@include min-screen($screen__s) {
    .cms-index-index,
    .cms-home {
        .image-and-text {
            .image-wrapper {
                width: 100vw;
                max-width: 100%;
                overflow: hidden;
                picture img {
                    min-width: 100vw;
                }
            }
        }
    }
}

@include max-screen($screen__s) {
    .cms-index-index,
    .cms-home {
        .video-container {
            max-height: 600px;
            .video-text {
                max-width: 80%;
                left: 10%;
            }
        }
        .home-search {
            .search-wrapper {
                width: 100%;
            }
        }
        .new-product-wrapper {
            .new-product-info-wrapper {
                width: 100%;
            }
            .info-wrapper {
                .info-text {
                    font-size: $font-size__base;
                    line-height: 19px;
                }
            }
        }
        .image-and-text {
            .image-wrapper {
                picture {
                    img {
                        height: 600px;
                    }
                }
            }
            .info-wrapper .info-text {
                font-size: $font-size__base;
                line-height: $line-height__base;
            }
        }
        .home-sectors {
            .sectors-wrapper {
                max-width: 400px;
            }
            .sector-wrapper {
                @include lib-vendor-prefix-flex-basis(100%);
            }
            .info-wrapper {
                padding: $indent__m;
            }
        }
        .text-button {
            .subtitle {
                font-size: $font-size__base;
                line-height: $line-height__base;
            }
        }
        .social-media {
            .poster-wrapper {
                @include lib-vendor-prefix-flex-basis(100%);
                &:nth-child(n+2) {
                    display: none;
                }
            }
        }
        .fairs-events-wrapper {
            padding: $indent__l 8.5% $indent__xl;
            .event {
                .img-wrapper {
                    max-width: 95px;
                }
                .texto {
                    font-size: $font-size__base;
                    line-height: $line-height__xs;
                }
            }
        }
    }
}

@include screen($screen__s, $screen__l) {
    .cms-index-index,
    .cms-home {
        .home-sectors {
            .sectors-wrapper {
                max-width: 800px;
            }
            .sector-wrapper {
                @include lib-vendor-prefix-flex-basis(50%);
            }
            .info {
                .hover-info {
                    .subtitle {
                        margin-bottom: 8%;
                        font-size: $font-size__base;
                        line-height: $line-height__base;
                    }
                }
            }
            .info-wrapper {
                padding: $indent__m;
            }
        }
        .social-media {
            margin-top: $indent__xl;
            .poster-wrapper {
                @include lib-vendor-prefix-flex-basis(49%);
                &:nth-child(n+3) {
                    display: none;
                }
            }
        }
    }
}

@include min-screen($screen__lx) {
    .cms-index-index,
    .cms-home {
        .home-search .search-text {
            margin-right: $indent__xl;
            @include lib-vendor-prefix-flex-basis(25%);
        }
    }
}
@include max-screen($screen__lx) {
    .cms-index-index,
    .cms-home {
        .home-search {
            .search-content {
                flex-direction: column;
                align-items: stretch;
                padding: $indent__m;
            }

            .search-text {
                margin-bottom: 0;
                .search-title {
                    margin-bottom: $indent__ml;
                }
                .search-subtitle {
                    display: none;
                }
            }
        }
    }
}
@include max-screen($screen__l) {
    .cms-index-index,
    .cms-home {
        .video-container {
            .home-video {
                left: -200px;
                position: relative;
            }
        }
    }
}

@include max-screen($screen__m) {
    .cms-index-index,
    .cms-home {
        .home-search {
            .search-wrapper {
                margin: auto;
            }
            .search-form {
                flex-direction: column;
                align-items: stretch;
                .select-wrapper:not(:last-child) {
                    margin-bottom: $indent__ml;
                }
            }
            .action.primary {
                margin-top: $indent__l;
            }
        }
        .new-product-wrapper {
            padding-bottom: $indent__xxl;
            .new-product-info-wrapper {
                flex-direction: column;
            }
            .info-wrapper {
                padding: 0 $indent__m;
            }
            .image-wrapper {
                width: 100%;
                max-width: 500px;
            }
        }
        .social-media .social-block-wrapper {
            padding: 30px 7%;
        }
    }
}

@include min-screen($screen__m) {
    .cms-index-index,
    .cms-home {
        .home-search {
            justify-content: center;

            .search-content {
                padding: $indent__xxl $indent__xl;
                justify-content: center;
            }

            .search-text {
                margin-right: auto;
                min-width: 200px;
            }

            .searcher {
                width: 100%;
            }

            .search-form {
                .select-wrapper {
                    flex-grow: 1;
                    @include lib-vendor-prefix-flex-basis(230px);
                    margin-right: $indent__ml;
                }

                .action.primary {
                    flex-grow: 0;
                }
            }
        }
        .new-product-wrapper {
            padding-bottom: $indent__xxl;
            .info-wrapper {
                max-width: 43%;
                .info-text {
                    font-size: $font-size__l;
                    line-height: 27px;
                }
            }
            .image-wrapper {
                max-width: 50%;
            }
        }
    }
}

@include min-screen($screen__l) {
    .cms-index-index,
    .cms-home {
        .home-search {
            .search-content {
                align-items: flex-end;
            }
        }
        .social-media {
            margin-top: $indent__xxxl;
        }
    }
}

@include screen(500px, $screen__s) {
    .cms-index-index,
    .cms-home {
        .fairs-events-wrapper {
            .events {
                margin: 0 15%;
            }
        }
    }
}

@include screen(400px, 500px) {
    .cms-index-index,
    .cms-home {
        .fairs-events-wrapper {
            .events {
                margin: 0 10%;
            }
        }
    }
}

@include max-screen($screen__xs) {
    .cms-index-index,
    .cms-home {
        .social-media .lightbox.active {
            .lightbox-container {
                height: 45%;
            }
        }
    }
}
