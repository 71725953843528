body[class^='cms-normas-de-mantenimiento'] {
    .column.main {
        padding-bottom: 0;
    }
}


.normas-wrapper {
    .article-header {
        background-color: $c-background-black;

        .article-title {
            padding-bottom: 1.8rem;
            padding-top: 1.8rem;
            margin-top: 0;
            margin-bottom: 0;
            letter-spacing: 0;
        }
    }

    .article-body {
        .options {
            padding-left: 1.25rem;

            .option {
                font-weight: 700;

                .p {
                    font-weight: 400;
                    margin-left: 0.7rem;
                }
            }
        }
    }

    .article-body-aviso-legal {
        .options {
            padding-left: 1.5rem;

            .option {
                font-weight: 700;

                .p {
                    font-weight: 400;
                    margin-left: 0.7rem;
                }
            }
        }

        .table,
        .table2 {
            tr {
                border-bottom: 1px solid black;

                .celda-izquierda {
                    font-weight: 700;
                }

                td {
                    a {
                        &:link,
                        &:visited,
                        &:active {
                            color: #333;
                        }

                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }

    .article-body-parrafos {
        .titulo-parrafo {
            font-weight: 700;
        }

        .parrafos {
            .parrafo {
                margin-bottom: 2rem;

                &:last-child {
                    margin-bottom: 0rem;
                }

                a {
                    &:link,
                    &:visited,
                    &:active {
                        color: #333;
                    }

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }

        .options {
            padding-left: 1.5rem;

            .option {
                font-weight: 700;

                .p {
                    font-weight: 400;
                    margin-left: 0.7rem;
                }
            }
        }
    }

    .article-body-parrafos-especial2.article-body-parrafos {
        .parrafos {
            .parrafo {
                margin-bottom: 2rem;

                &:last-child {
                    margin-bottom: 0rem;
                }
            }
        }
    }

    .article-body-parrafos-especial {
        .parrafos {
            .parrafo {
                margin-bottom: 2rem;

                &:last-child {
                    margin-bottom: 0rem;
                }
            }
        }
    }
}

.top-banner {
    font-size: 0;
    position: relative;

    .top-title {
        position: absolute;
        top: 22.5rem;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        letter-spacing: 0;
    }
}


@include max-screen($screen__xxxl) {

    .normas-wrapper {
        .article-title {
            margin-left: 12rem;
        }

        .article-body {
            margin-left: 12rem;
            margin-right: 12rem;

            .options {
                margin-top: 4.8rem;
                margin-bottom: 4.8rem;

                .option {
                    line-height: 19px;
                    letter-spacing: 0;
                }
            }
        }

        .article-body-aviso-legal {
            margin-left: 12rem;
            margin-right: 12rem;

            .options {
                margin-top: 4.8rem;
                margin-bottom: 4.8rem;

                .option {
                    line-height: 19px;
                    letter-spacing: 0;
                }
            }

            .table {
                margin-bottom: 4.8rem;
            }

            .table2 {
                margin-top: 4.8rem;

                .celda-derecha {
                    p {
                        margin-left: 50rem;
                        margin-bottom: 0rem;
                    }
                }
            }
        }

        .article-body-parrafos {
            margin-left: 12rem;
            margin-right: 12rem;
            margin-top: 4.8rem;
            margin-bottom: 4.8rem;
        }

        .article-body-parrafos-especial {
            margin-left: 12rem;
            margin-right: 12rem;
            margin-top: 4.8rem;
            margin-bottom: 2rem;
        }

        .article-body-parrafos-especial2.article-body-parrafos {
            margin-left: 12rem;
            margin-right: 12rem;
            margin-top: 0rem;
            margin-bottom: 4.8rem;
        }
    }
}


@include min-screen($screen__xxxl) {

    .top-banner {
        display: flex;
        justify-content: center;
    }

    .normas-wrapper {
        .article-title {
            margin-left: 12rem;
        }

        .article-body {
            margin-left: 12rem;
            margin-right: 12rem;

            .options {
                margin-top: 4.8rem;
                margin-bottom: 3.8rem;

                .option {
                    line-height: 19px;
                    letter-spacing: 0;
                }
            }
        }

        .article-body-aviso-legal {
            margin-left: 12rem;
            margin-right: 12rem;

            .options {
                margin-top: 4.8rem;
                margin-bottom: 3.8rem;

                .option {
                    line-height: 19px;
                    letter-spacing: 0;
                }
            }

            .table {
                margin-bottom: 4.8rem;
            }

            .table2 {
                margin-top: 4.8rem;

                .celda-derecha {
                    p {
                        margin-left: 50rem;
                        margin-bottom: 0rem;
                    }
                }
            }
        }

        .article-body-parrafos {
            margin-left: 12rem;
            margin-right: 12rem;
            margin-top: 4.8rem;
            margin-bottom: 4.8rem;
        }

        .article-body-parrafos-especial {
            margin-left: 12rem;
            margin-right: 12rem;
            margin-top: 4.8rem;
            margin-bottom: 2rem;
        }

        .article-body-parrafos-especial2.article-body-parrafos {
            margin-left: 12rem;
            margin-right: 12rem;
            margin-top: 0rem;
            margin-bottom: 4.8rem;
        }

    }
}


@include min-screen($screen__xl) {
    .normas-wrapper {
        .article-header {
            display: flex;
            justify-content: center;

            .article-title {
                width: 1310px;
            }
        }

        .article-body {
            display: flex;
            justify-content: center;

            .options {
                width: 1177px;
                margin-top: 4.8rem;
                margin-bottom: 3.8rem;
            }
        }

        .article-body-aviso-legal {
            display: flex;
            justify-content: center;

            .options {
                width: 1177px;
                margin-top: 4.8rem;
                margin-bottom: 3.8rem;
            }

            .parrafos {
                width: 1177px;
            }
        }

        .article-body-parrafos,
        .article-body-parrafos-especial {
            display: flex;
            justify-content: center;

            .parrafos {
                width: 1191px;
            }
        }

        .article-body-parrafos-especial2.article-body-parrafos {
            display: flex;
            justify-content: center;

            .parrafos {
                width: 1191px;
            }
        }
    }
}

@include max-screen($screen__l) {

    .normas-wrapper {
        .article-title {
            margin-left: 4.8rem;
        }

        .article-body {
            margin-left: 8.7rem;
            margin-right: 5rem;

            .options {
                margin-top: 4.8rem;
                margin-bottom: 4.8rem;

                .option {
                    line-height: 19px;
                    letter-spacing: 0;
                }
            }

            /*  .table{
                margin-bottom: 4.8rem;
              } */
        }

        .article-body-aviso-legal {
            margin-left: 8.7rem;
            margin-right: 5rem;

            .options {
                margin-top: 4.8rem;
                margin-bottom: 4.8rem;

                .option {
                    line-height: 19px;
                    letter-spacing: 0;
                }
            }

            .table {
                margin-bottom: 4.8rem;
            }

            .table2 {
                margin-top: 4.8rem;

                .celda-derecha {
                    p {
                        margin-left: 20rem;
                        margin-bottom: 0rem;
                    }
                }
            }
        }

        .article-body-parrafos {
            margin-left: 8.7rem;
            margin-right: 5rem;
            margin-top: 4.8rem;
            margin-bottom: 4.8rem;
        }

        .article-body-parrafos-especial {
            margin-left: 8.7rem;
            margin-right: 5rem;
            margin-top: 4.8rem;
            margin-bottom: 2rem;
        }

        .article-body-parrafos-especial2.article-body-parrafos {
            margin-left: 8.7rem;
            margin-right: 5rem;
            margin-top: 4.8rem;
            margin-bottom: 4.8rem;
        }
    }
}

@include min-screen($screen__m) {

    .top-banner {
        .top-title {
            font-size: $h1__font-size;
        }
    }
}


@include max-screen($screen__m) {
    .normas-wrapper {
        .article-title {
            margin-left: 2.4rem;
        }

        .article-body {
            margin-left: 2.4rem;

            .options {
                margin-top: 1.7rem;
                margin-bottom: 4.8rem;

                .option {
                    line-height: 19px;
                    letter-spacing: 0;
                }
            }
        }

        .article-body-aviso-legal {
            margin-left: 2.4rem;

            .options {
                margin-top: 1.7rem;
                margin-bottom: 4.8rem;

                .option {
                    line-height: 19px;
                    letter-spacing: 0;
                }
            }

            .table2 {
                margin-top: 1.7rem;

                .celda-derecha {
                    p {
                        margin-left: 0rem;
                        margin-bottom: 0rem;
                    }
                }
            }
        }

        .article-body-parrafos {
            margin-left: 2.4rem;
            // margin-right: 5rem;
            margin-top: 1.7rem;
            margin-bottom: 4.8rem;
        }

        .article-body-parrafos-especial {
            margin-left: 2.4rem;
            // margin-right: 5rem;
            margin-top: 1.7rem;
            margin-bottom: 2rem;
        }

        .article-body-parrafos-especial2.article-body-parrafos {
            margin-left: 2.4rem;
            // margin-right: 5rem;
            margin-top: 0rem;
            margin-bottom: 4.8rem;
        }

    }
}


@include max-screen($screen__s) {

    .category-view {
        position: relative;
        .top-banner {
            .top-title {
                top: 45%;
                @include lib-font-size(28px);
            }
        }
        .category-description {
            top: 70%;
        }
    }
}


@include min-screen($screen__sx) {

    .top-banner {
        .top-title {
            width: 100%;
        }
    }
}
