@include lib-font-face(
    $family-name: $font-family-name__open-sans,
    $font-path: '../fonts/opensans/light/opensans-300',
    $font-weight: 300,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $font-family-name__open-sans,
    $font-path: '../fonts/opensans/regular/opensans-400',
    $font-weight: 400,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $font-family-name__open-sans,
    $font-path: '../fonts/opensans/semibold/opensans-600',
    $font-weight: 600,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $font-family-name__open-sans,
    $font-path: '../fonts/opensans/bold/opensans-700',
    $font-weight: 700,
    $font-style: normal
);

//
// On4u Typography
// ---------------------------------------------------------

@include lib-font-face(
    $family-name: $font-family__Taz,
    $font-path: '../fonts/Taz/Taz-SemiLight',
    $font-weight: 300,
    $font-style: normal
);
@include lib-font-face(
    $family-name: $font-family__Taz,
    $font-path: '../fonts/Taz/Taz-SemiLightItalic',
    $font-weight: 300,
    $font-style: italic
);

@include lib-font-face(
    $family-name: $font-family__Taz,
    $font-path: '../fonts/Taz/Taz-Regular',
    $font-weight: 400,
    $font-style: normal
);
@include lib-font-face(
    $family-name: $font-family__Taz,
    $font-path: '../fonts/Taz/Taz-RegularItalic',
    $font-weight: 400,
    $font-style: italic
);

@include lib-font-face(
    $family-name: $font-family__Taz,
    $font-path: '../fonts/Taz/Taz-Bold',
    $font-weight: 700,
    $font-style: normal
);
@include lib-font-face(
    $family-name: $font-family__Taz,
    $font-path: '../fonts/Taz/Taz-BoldItalic',
    $font-weight: 700,
    $font-style: italic
);

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    h1 {
        @include lib-css(font-size, $h1__font-size-desktop);
        @include lib-css(margin-bottom, $h1__margin-bottom__desktop);
    }
}

.items {
    @include lib-list-reset-styles();
}
