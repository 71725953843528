#country-selector-popup{
    width: 503px;
    height: 386px;
    max-height: 386px;
    position: fixed;
    top: 20vh;
    background-color: $c-background-white;
    z-index: 10;
    left: 50%;
    transform: translateX(-50%);
    padding: 72px 48px;
    box-sizing: border-box;
    transition: max-height 0.5s ease-in;

        &.active{
            display: block;
        }
        &.hidden{
            display: none;
        }
        .select-wrapper{
            margin-bottom: $indent__ml;

            .selected-input-option {
                padding: $indent__ml 24px $indent__ml 24px;
            }
        }
        .select-input-options{
            max-height: 400px;
        }
        .mage-error {
            border-color: $form-validation-note__color-error;
            color: $form-validation-note__color-error;
            &.active{
                display: block;
            }
            &.hidden{
                display: none;
            }
        }
        .action.primary{
            margin-top: $indent__ml;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
        }
        .close{
            position: absolute;
            top: 0;
            right: 0;
            background: url("../images/icon-close.svg") no-repeat center;
            cursor: pointer;
            width: 24px;
            height: 24px;
            margin: 24px;
        }
}

#popup-overlay{
    //display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9;
    background-color: rgba(0,0,0,0.8);
    width: 100vw;
    height: 100vh;
        &.active{
            display: block;
        }
        &.hidden{
            display: none;
        }
}

.noscroll-class {
    overflow: hidden;
}

@include max-screen($screen__m) {
    #country-selector-popup{
        width: 95%;
        height: 370px;
    }
}
