//
//  Common styles for every pages
//  ---------------------------------------------
//
//  Reset styles
//  ---------------------------------------------
#maincontent {
    padding: 0;
}

a {
    color: $c-text-white;
    &:link,
    &:visited,
    &:active {
        color: $c-text-white;
        text-decoration: none;
        text-transform: none;
    }
    &:hover {
        text-decoration: underline;
    }
}

//
//  Common Blocks
//  ---------------------------------------------
.social-media-wrapper {
    display: inline-block;
    width: 184px;
    .social-media-icons-list {
        display: flex;
        justify-content: space-between;
        padding-left: 0;
        margin-bottom: 0;
    }
    .media-icon {
        display: inline-block;
        width: 24px;
        height: 24px;
        margin-bottom: 0;
        &:hover {
            cursor: pointer;
            svg .st0 {
                fill: $c-text-hover;
            }
        }
    }
}

//
// Full website styles
// ----------------------------------------------
body.video-opened {
    height: 100%;
    overflow: hidden;
}

//
// Products Slider Styles
// -----------------------------------------------
.slider-section {
    text-align: center;
    padding: 0 3.5%;
    max-width: $screen__xxl;
    margin: 65px auto auto;
}
.slider-widget {
    .block-title {
        margin-bottom: $indent__l;
    }
    .title {
        color: $c-text-black;
        margin-bottom: $indent__xs;
    }
}
.products-slider {
    .slick-slide div > .product-item {
        padding-left: 0;
    }
    .product-item-info {
        display: flex;
        flex-direction: column;
        .product-item-photo {
            flex-shrink: 0;
        }
        .product-item-details {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            text-align: left;
            margin-top: $indent__ml;
            .product-item-actions {
                justify-content: flex-end;
                margin-top: $indent__ml;
            }
        }
    }
    .slick-list,
    .slick-slide {
        margin: 0;
    }
    button.slick-prev,
    button.slick-prev:hover {
        left: -20px;
    }
    button.slick-next,
    button.slick-next:hover {
        right: -20px;
    }
}

@include screen($screen__s, $screen__l) {
    .slider-section {
        padding: 0 20px;
        margin: 80px auto auto;
    }
    .products-slider {
        .slick-list {
            margin: 0 -20px;
        }
        .slick-slide {
            margin: 0 20px;
        }
        button.slick-prev,
        button.slick-prev:hover {
            left: 5%;
        }
        button.slick-next,
        button.slick-next:hover {
            right: 5%;
        }
    }
}

@include max-screen($screen__m) {
    .products-slider .product-item-photo {
        width: 100%;
    }
}

@include max-screen($screen__s) {
    .slider-section {
        margin: 50px auto;
        padding: 0 7%;
    }
}


