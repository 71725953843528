.page-header {
    position: fixed;
    left: 0;
    right: 0;
    z-index: 9;
    margin-bottom: 0;
    border-bottom: 0;
    background-color: rgba(8,8,8,0);
    .header.content {
        display: flex;
        .navigation-wrapper{
            .switcher-language{
                //switcher-mobile
                display: none;
            }
        }

        .switcher-language {
            color: $c-text-white;
            margin-right: 0;
            .country-selector-button{
                margin-right: 15px;
                strong span{
                    text-transform: capitalize;
                }
            }
            .label {
                font-weight: 400;
            }
            .options {
                &.active {
                    ul.dropdown {
                        max-height: 180px;
                        opacity: 1;
                        transition: max-height .7s ease-in, opacity 1s ease-in;
                    }
                }
                .action.toggle {
                    display: inline-flex;
                    align-items: center;
                    &.active {
                        display: inline-flex;
                    }
                }
                ul.dropdown {
                    display: block;
                    left: -23px;
                    overflow: hidden;
                    min-width: 0;
                    border: 1px solid $c-greyish-brown;
                    background-color: $c-background-black;
                    max-height: 0;
                    opacity: 0;
                    margin-top: 0;
                    transition: max-height .6s .2s ease-in, opacity .6s ease-in;
                    .switcher-option {
                        padding: 0 15px;
                        & > a {
                            text-align: left;
                            color: $c-text-white;
                        }
                        &:hover {
                            background-color: $c-background-black;
                            text-decoration: underline;
                        }
                    }
                    &::before,
                    &:before,
                    &::after,
                    &:after {
                        content: none;
                    }
                }
            }
        }
        .level0-element {
            margin-bottom: 0;
            .arrow-icon {
                width: 14px;
                height: 7.45px;
                padding-left: 10px;
                position: relative;
                .icon-arrow {
                    position: absolute;
                    width: 14px;
                    top: 50%;
                    right: 0;
                    transform: translateY(-50%);
                    transition: transform 0.5s ease-in;
                }
            }
            &.active.closing {
                .main-link {
                    color: $c-text-hover;
                    .arrow-icon .icon-arrow {
                        transform: translateY(-50%);
                        transition: transform 0.5s ease-in;
                    }
                }
            }
            &.active {
                .main-link {
                    color: $c-text-hover;
                    .arrow-icon .icon-arrow {
                        transform: translateY(-50%) rotate(-180deg);
                        transition: transform 0.5s ease-in;
                        .st0 {
                            fill: $c-text-hover;
                        }
                    }
                }
            }
        }
    }

    .logo {
        float: none;
        margin: 0;
    }

    .main-link {
        display: inline-flex;
        align-items: center;
        cursor: pointer;
        font-weight: 700;
        &:hover {
            color: $c-text-hover;
            text-decoration: none;
            .arrow-icon .st0 {
                fill: $c-text-hover;
            }
        }
    }
}

@include max-screen($screen__m) {
    .page-header{
        .header.content{
            .switcher-language{
                .country-selector-button{
                    //switcher desktop
                    display: none;
                }
            }
        }
    }
    .menu-open {
        height: 100%;
        body {
            height: 100%;
            overflow: hidden;
            position: relative;
            width: 100%;
        }
        .page-header {
            .header.content {
                background-color: rgba(0, 0, 0, 1) !important;

                .navigation-wrapper {
                    left: 0;
                    overflow-y: scroll;
                    .switcher-language{
                        //switcher-mobile
                        display: block;
                        .country-selector-button{
                            display: inline-block;
                        }
                    }
                }
        }
        }
        .action.nav-toggle {
            .menu-title {
                color: $c-text-hover;
            }
            .menu-icon .st0 {
                fill: $c-text-hover;
            }
        }
    }

    .main-navigation {
        margin-bottom: 25px;
    }

    .menu-element {
        .element-icon {
            display: none;
        }
        .subtitle {
            display: none
        }
    }

    .level0-list {
        padding-left: 0;
        margin-bottom: 0;
        .level0-element {
            padding-left: $indent__xll;
            margin-bottom: 0;
            &:not(:last-child) {
                border-bottom: 1px solid $c-menu-element-underline-black;
            }
            &.closing {
                .level1-list {
                    max-height: 0 !important;
                    transition: max-height 1s cubic-bezier(0.04, 0.63, 0.61, 0.9);
                }
            }
            &.active {
                .main-link {
                    color: $c-text-hover;
                }
                .level1-list {
                    max-height: 300px;
                    transition: max-height 1.5s cubic-bezier(0.47, 0, 0.42, 0.91);
                }
            }
            &.higher {
                &.active {
                    .level1-list {
                        max-height: 540px;
                    }
                }
            }
        }
    }

    .main-link {
        line-height: 46px;
        &:active,
        &:visited,
        &:link {
            text-decoration: none;
        }
    }

    .level1-list {
        max-height: 0;
        overflow: hidden;
        transition: max-height 1s cubic-bezier(0.04, 0.63, 0.61, 0.9);
        .level1-element {
            padding-bottom: 30px;
            &:first-child {
                padding-top: 30px;
            }
            &.watch-all {
                margin-right: $indent__xll + $indent__xl;
                border-top: 1px solid $c-greyish-brown;
                .menu-element {
                    font-weight: $font-weight__bold;
                    display: block;
                    padding-top: $indent__xl;
                }
            }
        }
    }

    .page-header {
        .header.content {
            justify-content: space-between;
            align-items: center;
            padding: 17px 25px 25px;
        }
        .social-media-wrapper {
            display: none;
        }
    }

    .action.nav-toggle {
        display: flex;
        align-items: center;
        position: relative;
        top: 0;
        left: 0;
        cursor: pointer;
        .menu-icon {
            width: 24px;
            height: 24px;
        }
        .menu-title {
            color: $c-text-white;
            font-weight: 700;
            padding-left: 10px;
        }
    }

    .logo {
        width: 67.5px;
        height: 40px;
    }

    .navigation-wrapper {
        position: fixed;
        top: 82px;
        bottom: 0;
        background-color: $c-background-black;
        left: -100%;
        width: 100%;
        transition: left 0.5s ease-out;
    }

    .switcher-language {
        min-width: 70px;
        text-align: right;
    }
}

@include min-screen($screen__m) {
    .page-header {
        &.active {
            background-color: $c-background-black !important;
        }
        .header.content {
            justify-content: flex-end;
            align-items: center;
            padding: 16px 3.4%;
        }

        .action.nav-toggle {
            display: none;
        }

        .logo {
            margin-right: 10%;
        }

        .navigation-wrapper {
            flex-grow: 2;
            margin-right: 6.5%;
        }

        .level0-list {
            display: flex;
            flex-grow: 2;
            justify-content: space-between;
            max-width: 800px;
            margin-left: auto;
            padding-left: 0;
            margin-bottom: 0;
        }

        .main-link {
            .arrow-icon {
                width: 14px;
                padding-left: 10px;
            }
            &:hover {
                .arrow-icon {
                    animation: arrow-scroll 1s ease;
                    @keyframes arrow-scroll {
                        0%   { transform:translateY(0); }
                        25%  { transform:translateY(2px); }
                        75%  { transform:translateY(0) }
                        100% { transform:translateY(0); }
                    }
                }
            }
        }

        .level1-list {
            display: none;
            padding-left: 0;
            margin-bottom: 0;

            .level1-element {
                margin-bottom: 0;
                padding-bottom: 30px;
                @include lib-vendor-prefix-flex-basis(47%);
                &.watch-all {
                    text-align: center;
                    flex-basis: 100%;
                    border-top: 1px solid $c-greyish-brown;
                    padding-top: $indent__l;
                    padding-bottom: $indent__ml;
                    .menu-element {
                        display: inline-block;
                        font-weight: $font-weight__bold;
                        text-transform: uppercase;
                        font-size: $font-size__base;
                        .title {
                            font-size: $font-size__base;
                            line-height: normal;
                        }
                    }
                }
            }
        }

        .social-media-wrapper {
            padding-left: 12px;
            padding-right: 12px;
            margin-right: 3.5%;
        }

        .main-navigation {
            .level0-element.has-child {
                .full-wrapper {
                    display: flex;
                    max-height: 0;
                    opacity: 0;
                    justify-content: space-between;
                    position: absolute;
                    left: 0;
                    right: 0;
                    padding: 0;
                    background-color: $c-background-black;
                    transition: max-height .8s cubic-bezier(0.47, 0, 0.42, 0.91), opacity .5s ease-out;
                }
                .content-wrapper {
                    display: flex;
                    width: 100%;
                    max-width: 800px;
                    padding-top: 0;
                    height: 0;
                }
                .level1-list {
                    display: flex;
                    flex-wrap: wrap;
                    width: 100%;
                    justify-content: space-between;
                    visibility: hidden;
                    transition: visibility .1s 1s;
                }
                &.active {
                    .full-wrapper {
                        //display: flex;
                        max-height: 540px;
                        opacity: 1;
                        padding: 16px 3.4%;
                        border-top: 1px solid $c-menu-element-underline-black;
                        transition: max-height .7s cubic-bezier(0.47, 0, 0.42, 0.91), opacity .6s .1s ease-in;
                    }
                    .content-wrapper {
                        padding-top: 30px;
                        height: auto;
                    }
                    .level1-list {
                        visibility: visible;
                        transition: visibility .1s;
                    }
                }
                &.closing {
                    .full-wrapper {
                        max-height: 0 !important;
                        opacity: 0 !important;
                        transition: max-height .8s cubic-bezier(0.47, 0, 0.42, 0.91), opacity .5s ease-out;
                    }
                }
            }
        }

        .menu-element {
            display: flex;
            align-items: center;
            height: 100%;
            &:hover {
                text-decoration: none;
                .title {
                    color: $c-text-hover;
                }
                .element-icon > svg {
                    &:not(.warranty-icon) .st2,
                    &:not(.warranty-icon) .st0 {
                        fill: $c-text-hover;
                    }
                    &.warranty-icon {
                        .hover-stroke {
                            stroke: $c-text-hover;
                        }
                        .hover-fill {
                            fill: $c-text-hover;
                        }
                    }
                }

            }
            .element-icon {
                width: 39px;
                padding-right: 15px;
                max-height: 39px;
                svg {
                    max-height: 39px;
                    width: 39px;
                }
            }
            .title {
                display: block;
                font-size: $font-size__l;
                line-height: 27px;
            }
            .subtitle {
                display: block;
                font-size: $font-size__base;
                line-height: 24px;
                color: $c-greyish-brown;
            }
        }
    }
}

@include screen($screen__m, $screen__lx) {
    .page-header {
        .header.content {
            justify-content: space-between;
            flex-wrap: wrap;
        }
        .logo {
            margin-right: auto;
        }
        .full-wrapper {
            top: 125px;
        }
        .level0-list {
            margin: auto;
            .level0-element {
                margin-bottom: 0;
                &.has-child {
                    .content-wrapper {
                        margin: auto;
                    }
                }
            }
        }
        .level1-list {
            display: none;
            padding-left: 0;
            margin-bottom: 0;
            .level1-element {
                margin-bottom: 0;
            }
        }

        .navigation-wrapper {
            flex-grow: 2;
            order: 4;
            width: 100%;
            margin: auto;
            padding-top: 25px;
        }

        .social-media-wrapper {
            padding-left: 12px;
            padding-right: 12px;
            margin-right: 3.5%;
        }
    }
}

@include min-screen($screen__lx) {
    .main-navigation {
        .level0-element.has-child {
            .full-wrapper {
                top: 81px;
                &:before,
                &::before {
                    content: '';
                    display: block;
                    width: 84px;
                    height: 1px;
                    margin-right: 10%;
                    flex-shrink: 0;
                }
                &:after,
                &::after {
                    content: '';
                    display: block;
                    height: 1px;
                    width: calc(247.64px + 3.5%);
                }
            }
            .content-wrapper {
                display: flex;
                justify-content: space-between;
                flex-grow: 2;
                max-width: 800px;
                margin-left: auto;
                margin-right: 6.5%;
            }
        }
    }
}

@include max-screen($screen__m) {
    .menu-open .action.nav-toggle .menu-icon {
        /* Icon 4 */

        path {
            transition: .25s ease-in-out;
        }

        path:nth-child(1) {
            transform: translateX(5px) translateY(-3px) rotate(45deg);
        }

        path:nth-child(2) {
            width: 0;
            opacity: 0;
        }

        path:nth-child(3) {
            transform: translateX(-5px) translateY(4px) rotate(-45deg);
        }
    }
    .action.nav-toggle .menu-icon {
        /* Icon 4 */

        path {
            transition: .25s ease-in-out;
        }

        path:nth-child(1) {
            transform: translateX(0) translateY(0) rotate(0deg);
        }

        path:nth-child(2) {
            opacity: 1;
        }

        path:nth-child(3) {
            transform: translateX(0) translateY(0) rotate(0deg);
        }
    }
}
