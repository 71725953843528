$product-grid-items-per-row-layout-default          : 2 !default;

$product-grid-items-per-row-layout-1-screen-s       : 3 !default;
$product-grid-items-per-row-layout-1-screen-m       : 4 !default;
$product-grid-items-per-row-layout-1-screen-l       : 5 !default;

$product-grid-items-per-row-layout-2-left-screen-s  : 3 !default;
$product-grid-items-per-row-layout-2-left-screen-m  : 4 !default;
$product-grid-items-per-row-layout-2-left-screen-l  : '' !default;

$product-grid-items-per-row-layout-2-right-screen-s : 3 !default;
$product-grid-items-per-row-layout-2-right-screen-m : 4 !default;
$product-grid-items-per-row-layout-2-right-screen-l : '' !default;

$product-grid-items-per-row-layout-3-screen-s       : 3 !default;
$product-grid-items-per-row-layout-3-screen-m       : '' !default;
$product-grid-items-per-row-layout-3-screen-l       : '' !default;

$product-grid-items-padding                         : 0 $indent__base $indent__base !default;
$product-grid-items-margin                          : 0 0 $indent__s !default;

$product-name-text-decoration                       : none !default;
$product-name-text-decoration-hover                 : $link__hover__text-decoration !default;

$toolbar-mode-icon-font-size                        : 26px !default;
$product-h1-margin-bottom-desktop                   : $indent__base !default;

@import 'module/listings';
@import 'module/toolbar';
@import 'module/gallery';
@import 'module/seoattributes';

//
//  Category view
//  ---------------------------------------------

.catalog-category-view #every-product-hidden {
    box-sizing: border-box;
    width: 80%;
    max-width: $screen__sx;
    margin: auto;
    margin-top: $indent__xl;
}

.old-price,
.old.price {
    text-decoration: line-through;
}

.prices-tier {
    .price-container {
        .price-including-tax {
            + .price-excluding-tax {
                &:before {
                    content: '(' attr(data-label) ': ';
                }

                &:last-child:after {
                    content: ')';
                }
            }
        }

        .weee[data-label] {
            display: inline;

            .price {
                @include lib-font-size(11);
            }

            &:before {
                content: ' +' attr(data-label) ': ';
            }
        }
    }
}

.actual-price {
    font-weight: $font-weight__bold;
}

.catalog-product_compare-index {
    .page-main {
        .page-title-wrapper {
            text-align: center;
            h1 {
                color: $c-text-black;
            }
        }
        margin-top: 13.2rem;
        .tolist {
            color: $c-greyish-brown;
            font-weight: bold;
            margin-left: 48px;
        }
    }
}

.product.name a {
    @extend .abs-product-link;
}

.category-image {
    .image {
        display: block;
        height: auto;
        max-width: 100%;
    }
}

.category-description {
    //margin-bottom: $indent__base;
    position: absolute;
    top: 26.5rem;
    color: $c-text-white;
    text-align: center;
    width: 100%;
}

.category-cms {
    background-color: $c-background-grey;
    .subcategory-description-wrapper {
        text-align: center;
        .subcategory-description {
            text-align: left;
            display: inline-block;
            margin: 0 2.4rem;
            & > span {
              font-weight: bold;
              margin-bottom: 1.3rem;
              display: inline-block;
            }
            ul,
            ul > li {
              margin: 0;
              line-height: 1.4;
            }
            h2 {
                color: $color-black;
            }
        }
    }
    .compatibility {
        text-align: right;
        padding: 15px 40px;
        a {
            text-transform: uppercase;
            color: $c-text-black;
            font-weight: bold;

            .icon-compatibilidad,
            .icon-faqs,
            .icon-pdf {
                height: 13px;
                width: 13px;
                margin-right: 5px;
                display: inline-block;
                .st0 {
                    fill: $c-text-black;
                }
            }


            &:hover {
                text-decoration: none;
                color: $c-text-hover;
                .icon-faqs,
                .icon-compatibilidad,
                .icon-pdf {
                    .st0 {
                        fill: $c-text-hover;
                    }
                }
            }
        }
    }
}

//
//  Product images general container
//  ---------------------------------------------

.product-image-container {
    display: inline-block;
    max-width: 100%;
}

.product-image-wrapper {
    display: block;
    height: 0;
    overflow: hidden;
    position: relative;
    z-index: 1;
}

.product-image-photo {
    bottom: 0;
    display: block;
    height: auto;
    left: 0;
    margin: auto;
    max-width: 100%;
    position: absolute;
    right: 0;
    top: 0;
}

//
//  Product view
//  ---------------------------------------------

.product.media {
    .product.photo .photo.image {
        @extend .abs-adaptive-images-centered;
    }

    .placeholder .photo.container {
        max-width: 100%;
    }

    .notice {
        @include lib-css(color, $text__color__muted);
        @include lib-font-size($font-size__s);
        margin: $indent__s 0;
    }

    .product.thumbs {
        margin: $indent__base 0 $indent__l;
    }

    .items.thumbs {
        @include lib-list-inline();

        .active {
            display: block;
            line-height: 1;
        }
    }
}

.product.info.detailed {
    clear: both;
    margin-bottom: 30px;

    .additional-attributes {
        width: auto;
        @include lib-table-resize(
            $_th-padding-left   : 0,
            $_th-padding-right  : $indent__l,
            $_th-padding-bottom : $indent__s,
            $_td-padding-bottom : $indent__s
        );
    }
}

.product-info-main {
    .page-title-wrapper {
        .page-title {
            line-height: $line-height__base;
            margin-bottom: $indent__s;
        }
    }

    .stock {
        &.available,
        &.unavailable {
            display: inline-block;
            font-weight: $font-weight__bold;
            margin-right: $indent__base;
            text-transform: uppercase;
            vertical-align: top;
        }
    }

    .product {
        &.attribute {
            &.sku {
                display: inline-block;
                vertical-align: top;
                @include lib-css(color, $text__color__muted);

                > .value {
                    display: inline-block;
                    vertical-align: top;
                    word-break: break-all;
                }

                .type {
                    margin-right: $indent__xs;
                }
            }

            &.overview {
                margin: $indent__base 0;
            }
        }

        &.alert {
            margin: $indent__s 0;
        }
    }

    .price-box {
        margin-top: $indent__s;
    }

    .product-reviews-summary .reviews-actions {
        @include lib-font-size($font-size__base);
    }
}

.product-options-wrapper {
    .fieldset-product-options-inner {
        .legend {
            @include lib-css(font-weight, $font-weight__bold);
            @include lib-css(margin, 0 0 $indent__xs);
            @include lib-font-size(14px);
            border: none;
            display: inline-block;
            float: none;
            padding: 0;
        }

        //  Date & Time custom option (Affect Time that goes only after Date)
        input.datetime-picker {
            ~ select.datetime-picker {
                margin-top: $indent__s;
            }
        }

        &.required,
        &._required {
            .legend {
                &:after {
                    content: '*';
                    @include lib-typography(
                        $_font-size   : $form-field-label-asterisk__font-size,
                        $_color       : $form-field-label-asterisk__color,
                        $_font-family : $form-field-label-asterisk__font-family,
                        $_font-weight : $form-field-label-asterisk__font-weight,
                        $_line-height : $form-field-label-asterisk__line-height,
                        $_font-style  : $form-field-label-asterisk__font-style
                    );
                    @include lib-css(margin, $form-field-label-asterisk__margin);
                }
            }
        }
    }

    .field {
        .note {
            display: block;
        }

        .price-notice {
            @extend .abs-adjustment-incl-excl-tax;
        }
    }
}

.product-info-main,
.product-options-bottom {
    .price-box {
        .price-including-tax + .price-excluding-tax,
        .weee + .price-excluding-tax,
        .weee {
            @include lib-font-size(12);
            line-height: 14px;
            margin-bottom: $indent__xs;

            .price {
                @include lib-font-size(12);
                font-weight: $font-weight__bold;
            }
        }

        .price-wrapper .price {
            @include lib-font-size(18);
            font-weight: $font-weight__bold;
        }

        .price {
            white-space: nowrap;
        }
    }

    .special-price {
        display: block;
        margin: $indent__s 0;

        .price-container {
            @include lib-font-size(14);
        }

        .price-label + .price-wrapper {
            display: inline-block;
        }
    }

    .old-price,
    .special-price {
        .price-label {
            &:after {
                content: ': ';
            }
        }
    }

    .box-tocart {
        margin: $indent__base 0;

        .field.qty {
            padding-right: 0.75 * $indent__base;
        }

        .input-text.qty {
            $tocart-input-size: $button__line-height__l + 30px;
            height: $tocart-input-size;
            text-align: center;
            width: $tocart-input-size;
        }

        .actions {
            text-align: center;
        }

        .action.tocart {
            @extend .abs-button-l;
        }
    }

    .product-addto-links {
        margin: $indent__base 0;
    }

    .action.tocompare {
        @extend .abs-action-addto-product;
        vertical-align: top;
    }
}

.prices-tier {
    @extend .abs-reset-list;
    @include lib-css(background, $sidebar__background-color);
    margin: $indent__s 0;
    padding: $indent__s (0.75 * $indent__base);

    .price-container {
        display: inline-block;
    }

    .price-including-tax,
    .price-excluding-tax,
    .weee {
        display: inline-block;

        .price {
            @include lib-font-size(14);
            font-weight: $font-weight__bold;
        }
    }
}

.info-tag {
    padding: 3px 6px;
    border-radius: 5px;
    display: inline-block;
    margin: 0 0 8px;
    color: $c-text-white;
    @include lib-font-size(13);
    &.optimo {
        background-color: $c-optimal-green;
    }
    &.apto {
        background-color: $c-suitable-ochre;
    }
}

.catalog-product-view {
    .page-header {
        position: sticky;
    }
    .column.main .grid{
        @include lib-vendor-prefix-display(grid);
        display: -ms-grid;
        padding: 4rem 3.4%;
        align-items: center;
        .pre-title{
            @include min-screen($screen__s) {
                @include lib-heading(h1);
            }
            @include max-screen($screen__s) {
                @include lib-heading(h2);
            }
            margin-bottom: 0;
        }
        .page-title {
            color: $c-text-black;
            margin-bottom: 10px;
            .seoh1 {
                display: block;
                font-weight: normal;
                font-size: $font-size__l;
            }
        }
        .product {
            &.info {
                grid-area: description;
                -ms-grid-row-align: center;
                font-weight: $font-weight__light;
                .description{
                    h2,
                    h3,
                    h4,
                    span {
                        color: $c-text-black;
                    }
                    a {
                        color: $c-text-black;
                        text-decoration: underline;
                    }
                }
            }
            &.image {
                grid-area: image;
                -ms-grid-row-align: center;
                text-align: center;
            }
            &.used {
                grid-area: info;
                -ms-grid-row-align: center;
                font-weight: $font-weight__light;
                &.used-repuestos{
                    grid-column: 1;
                    -ms-grid-column: 1;
                    grid-row: 2;
                    -ms-grid-row: 2;
                    margin-top: 0;
                }
            }
            &.family {
                grid-area: family;
                color: $c-greyish-brown;
                text-align: center;
                padding-top: 4rem;
                -ms-grid-row-align: center;
                font-weight: $font-weight__light;
                .family-title {
                    font-weight: bold;
                    margin-bottom: 8px;
                    display: block;
                }
            }
        }
    }
    .repuesto-product-image{
        box-sizing: border-box;
        padding: 0 20%;
    }
    .compatible-products{
        margin-bottom: $indent__s;
        a{
            @include lib-link(
                $_link-color: $color-black,
                $_link-color-hover: $color-black,
                $_link-color-active: $color-black,
                $_link-color-visited: $color-black
            );
        }
    }
    .product-info-section{
        margin: $indent__l 0;
        &.productos_optimos,
        &.productos_aptos{
            margin: $indent__m 0;
        }
        &:first-child{
            margin-top: 0;
        }
        &:last-child{
            margin-bottom: 0;
        }
        .product-info-title {
            margin-bottom: $indent__s2;
            display: block;
        }
        .product-info-content{
            > span{
                display: block;
            }
        }
    }
    .anchor-content {
        background-color: $c-background-black;
        padding: 2rem;
        font-weight: bold;
        text-align: center;
        scroll-behavior: smooth;
        .anchor {
            padding: 0 2rem;
            &:hover {
                text-decoration: none;
                color: $c-text-hover;
            }
        }
        &.empty{
            .anchor-title{
                display: none;
            }
        }
        .to-element-link {
            display: block;
            position: fixed;
            bottom: 10rem;
            -ms-transform: rotate(90deg); /* IE 9 */
            -webkit-transform: rotate(90deg); /* Safari 3-8 */
            transform: rotate(90deg);
            right: 1.6rem;
            box-shadow: none;
            border: none;
            height: 40px;
            width: 40px;
            z-index: 100;
            svg {
                filter: drop-shadow( 3px 0px 2px rgba(0, 0, 0, .4));
                -webkit-filter: drop-shadow( 3px 0px 2px rgba(0, 0, 0, .4));
            }
        }
    }
    .anchored {
        position: relative;
        max-width: 1600px;
        margin: auto;
        box-sizing: border-box;
    }
    .product-view-section{
        margin: 0;
        &.anchored,
        .product-view-section-anchored-container{
            position: relative;
            max-width: 1600px;
            margin: 0 auto;
            box-sizing: border-box;
            padding: 0 3.4%;
        }
        .title{
            h3{
                @include lib-heading(h2);
                color: $c-text-black;
                margin: 9.6rem 0 5.6rem;
            }
        }
        &:first-child{
            .title{
                h3{
                    margin-top: 2.5rem;
                }
            }
        }
    }
    .functional_info {
        .magic-characteristics {
            text-align: left;
            max-width: 1600px;
            padding: 6rem 2rem 0;
            margin: auto;
            ol {
                column-count: 2;
                column-gap: 15px;
            }
        }
    }
    .usage_area {
        z-index: 1;
        .slider-and-video{
            margin-bottom: 9.4rem;
        }
        .slick-slider {
            padding-left: 0;
            margin-bottom: 0;
        }
        .slick-prev {
            left: 20px;
        }
        .slick-next {
            right: 20px;
        }
        .slick-dots {
            padding-top: 1.5rem;
            padding-left: 0;
            text-align: center;
            li {
                margin-bottom: 0;
                display: inline-block;
                button {
                    background: $c-icon-grey;
                    color: $c-icon-grey;
                    border: 1px solid $c-icon-grey;
                    border-radius: 50%;
                    font-size: 0;
                    width: 12px;
                    height: 12px;
                    padding: 0;
                    margin: 0 4px;
                }
                &.slick-active {
                    button {
                        background: $c-icon-black;
                        color: $c-icon-black;
                        border: 1px solid $c-icon-black;
                    }
                }
            }
        }
        .video-container{
            margin: auto;
            max-width: 1200px;
        }
        .video {
            position: relative;
            padding-bottom: 56.25%;
            iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border: none;
                -webkit-box-shadow: 7px 11px 13px -6px $c-greyish-brown;
                -moz-box-shadow: 7px 11px 13px -6px $c-greyish-brown;
                box-shadow: 7px 11px 13px -6px $c-greyish-brown;
            }
        }
    }
    .technical_info {
        background-color: $c-background-grey;
        overflow: hidden;
        margin-top: 5.6rem;
        &.usage-area-exists{
            margin-top: -7.2rem;
            z-index: 0;
            position: relative;
            .product-view-section-anchored-container{
                padding-top: 5.6rem;
                padding-bottom: 9.6rem;
            }
        }
        .product-view-section-anchored-container{

            padding-top: 0;
            padding-bottom: 9.6rem;
        }
        .product-view-section-content{
            display: flex;
            padding: 0 4%;
            .icon-pdf,
            .icon-normas,
            .icon-compatibilidad {
                .st0{fill:#000000;}
            }
            .information {
                table {
                    tr {
                        &:not(:last-child) {
                            border-bottom: 1px solid $c-icon-grey;
                        }
                    }
                }
            }
            .links {
                padding-left: 4rem;
                display: flex;
                font-weight: bold;
                flex-direction: column;
                justify-content: space-between;
                a {
                    color: $c-text-black;
                    font-weight: bold;
                    display: block;
                    line-height: 38px;
                    text-transform: uppercase;
                    font-size: 1.6rem;
                    svg {
                        width: 16px;
                        height: 16px;
                        display: inline-block;
                        margin-right: 10px;
                    }
                    &.action {
                        display: inline-block;
                        padding: 0;
                        &.secondary:hover {
                            color: $c-text-white;
                        }
                    }
                    &:hover {
                        text-decoration: none;
                        color: $c-text-hover;
                        svg .st0 {
                            fill: $c-text-hover;
                        }
                    }
                }
            }
        }
    }
    .accessories {
        .product-view-section-content{
            display: flex;
            flex-wrap: wrap;
            .accessory {
                display: inline-grid;
                display:-ms-inline-grid;
                padding: 2rem;
                border: 2px solid $c-background-grey;
                width: 63rem;
                margin: 2rem 2rem 0 0;
                span {
                    -ms-grid-row-align: center;
                    color: $c-text-brown-grey;
                    display: inline-block;
                    font-weight: bold;
                    max-width: 22rem;
                    grid-area: text;
                }
                img {
                    -ms-grid-row-align: center;
                    grid-area: photo;
                }
                a {
                    -ms-grid-row-align: center;
                    grid-area: button;
                    width: 170px;
                }
            }
        }
    }
    #magicimg-wrapper {
        font-size: 0;
        position: relative;
        display: inline-block;
        .magic-description {
            padding: 18px 40px;
            background-color: $color-white;
            color: $color-black;
            font-size: 16px;
            border: solid 1px #979797;
        }
        area {
            z-index: 1;
        }
        .mobile {
            display: none;
        }
    }
    #product-functional-info {
        text-align: center;
    }
}

.block-compare {
    background-color: $c-greyish-brown;
    text-align: center;
    position: fixed;
    bottom: 0;
    z-index: 50;
    width: 100%;
    margin-bottom: 0;
    .block-content {
        &.flex-display {
            display: inline-flex;
        }
        align-items: center;
        padding: 8px;
        box-sizing: border-box;
        .compare-product-items {
            display: flex;
            padding-left: 0;
            list-style: none;
            margin: 0;
            .compare-product-item {
                display: inline-block;
                width: 100%;
                max-width: 76px;
                padding: $indent__xss;
                margin: 0 $indent__s2 0 0;
                position: relative;
                background-color: $c-icon-white;
                &:last-child {
                    margin-right: 0;
                }
                .product-item-link {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 100%;
                    width: 100%;
                }
                img {
                    width: auto;
                    max-height: 76px;
                }
            }
        }
        .actions-toolbar {
            display: inline-block;
            margin: 0 0 0 $indent__l;
            .primary,
            .secondary {
                float: none;
            }
            .clear {
                margin: 15px 20px;
                width: auto;
            }
        }
    }
}

#included-pieces-table{
    width: 100%;
    max-width: 780px;
    .ipt-name{
        flex-basis: 60%;
    }
    .ipt-code{
        flex-basis: 20%;
    }
    .ipt-qty{
        flex-basis: 70px;
        text-align: right;
    }
}

#spare-parts-table{
    .spt-number {
        flex-basis: 45px;
        text-align: right;
    }
    .spt-name {
        flex-basis: calc(65% - 90px);
    }
    .spt-code {
        flex-basis: 35%;
    }
    .spt-qty {
        flex-basis: 45px;
        text-align: right;
    }
}

.spare-parts-info{
    display: flex;
    align-items: center;
    padding: 0 3.4% 4rem;
    .spare-parts-image{
        flex-basis: 50%;
        padding-right: 3%;
        box-sizing: border-box;
    }
    .spare-parts-table{
        flex-basis: 50%;
        padding-left: 3%;
    }
}

.sector-wrapper {
    background-color: $c-background-grey;
}
.sector:not(.searcher-select) {
    max-width: $screen__xxxl;
    margin: auto;
    padding-left: 3rem;
    padding-right: 3rem;
    &.construccion {
        .icon-construccion .st0 {
            fill:#000000;
        }
        padding: 8rem 6rem 0;
        .img {
            display: block;
            margin: auto;
        }
        .info {
            padding: 5rem 0;
            display: flex;
            justify-content: space-around;
            .characteristics {
                flex: 1 1 50%;
            }
            .title {
                flex: 1 1 50%;
            }
        }
    }
    &.limpieza {
        .icon-limpieza .st0 {
            fill:#000000;
        }
        padding-top: 12rem;
        padding-bottom: 25rem;
        display: flex;
        align-items: flex-start;
        .img {
            flex: 0 0 50%;
        }
        .info {
            flex: 0 1 50%;
            padding-left: 10rem;
            .characteristics {
                padding-left: 1.8rem;
            }
        }
    }
    &.industria {
        .icon-industria .st0 {
            fill:#000000;
        }
        margin-top: -6rem;
        margin-bottom: 10rem;
        max-width: 71%;
        display: inline-block;
        .img {
            display: block;
        }
        .info {
            padding: 7rem 2rem 0;
            display: flex;
            align-items: flex-start;
            .title {
                flex: 1 1 50%;
                box-sizing: border-box;
            }
            .characteristics {
                flex: 1 1 50%;
                margin-bottom: 0;
                align-self: center;
            }
        }
    }
    &.automocion {
        .icon-automocion .st0 {
            fill:#000000;
        }
        padding: 0 6rem;
        .img {
            display: block;
            margin: auto;
            padding: 1rem 0;
        }
        .info {
            padding: 5rem 0;
            display: flex;
            justify-content: space-around;
            .characteristics {
                flex: 1 1 50%;
            }
            .title {
                flex: 1 1 50%;
            }
        }
    }
    &.virus-epidemias,
    &.alimentario {
        .icon-virus-control .st0,
        .icon-virus-control .st2,
        .icon-alimentacion .st0 {
            fill:#000000;
        }
        padding-top: 12rem;
        padding-bottom: 12rem;
        display: flex;
        align-items: flex-start;
        .img {
            flex: 0 0 50%;
        }
        .info {
            flex: 0 1 50%;
            padding-left: 10rem;
            .characteristics {
                padding-left: 1.8rem;
            }
        }
    }
    &.virus-epidemias {
        padding-bottom: 25rem;
    }
    &.pest-control {
        .icon-control-plagas .st0 {
            fill:#000000;
        }
        margin-top: -6rem;
        margin-bottom: 10rem;
        margin-right: 0;
        text-align: right;
        max-width: 71%;
        .wrapper-pest {
            display: inline-flex;
            flex-direction: column;
        }
        .img {
            display: block;
            margin-left: auto;
        }
        .info {
            width: 100%;
            padding: 7rem 2rem 0;
            text-align: left;
            display: flex;
            align-items: flex-start;
            box-sizing: border-box;
            .title {
                flex: 1 1 50%;
                box-sizing: border-box;
            }
            .characteristics {
                margin-bottom: 0;
                flex: 1 1 50%;
                align-self: center;
            }
        }
    }
    .info {
        align-self: center;
        h2 {
            color: $c-text-black;
            margin-top: 1rem;
            font-size: 4.5rem;
        }
        svg {
            width: 30px;
            height: 30px;
        }
    }

}

.cms-manuales-instruccion-uso,
.cms-alt-ik_sectors,
.cms-descarga-catalogo {
    .top-banner {
        .top-title {
            width: 100%;
            top: 22.9rem;
            margin-top: 0;
        }

        .image-title {
            line-height: 1.2;
            margin-top: 26.5rem;
            font-size: 2rem;
            font-weight: 500;
        }
    }

    .page-title-wrapper {
        position: absolute;
        left: 50%;
        top: 0rem;
        transform: translateX(-50%);
        text-align: center;
        color: $c-text-white;
        width: 90%;
    }
}

.cms-alt-ik_sectors {
    .column.main {
        padding-bottom: 0;
    }
}

.cms-manuales-instruccion-uso,
.cms-descarga-catalogo {
    .products-manual-search {
        padding: 5rem;
        text-align: center;
        .search-wrapper {
            text-align: left;
            margin: auto;
            display: inline-block;
            width: 53rem;
            label {
                display: block;
            }
        }
    }
    .products-handbooks {
        padding-left: 2rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .message.info {
            margin: auto;
        }
        .product-handbook{
            display: inline-grid;
            display:-ms-inline-grid;
            padding: 2rem;
            border: 2px solid $c-background-grey;
            width: 63rem;
            margin: 2rem 2rem 0 0;
            &.hidden {
                display:none;
            }
            .text {
                -ms-grid-row-align: center;
                display: inline-block;
                max-width: 22rem;
                grid-area: text;
                span {
                    color: $c-text-brown-grey;
                    font-weight: bold;
                }
            }
            img {
                -ms-grid-row-align: center;
                grid-area: photo;
            }
            a {
                -ms-grid-row-align: center;
                grid-area: button;
                width: 170px;
            }
        }
    }
}


.ui-dialog-titlebar-close {
    @include lib-button-as-link();
}

.block.related {
    .action.select {
        margin: 0 $indent__xs;
    }
}

//
//  Sidebar product view
//  ---------------------------------------------

.sidebar {
    .product-items {
        .product-item {
            margin-bottom: $indent__base;
            position: relative;
        }

        .product-item-info {
            position: relative;
            width: auto;

            .product-item-photo {
                left: 0;
                position: absolute;
                top: 0;
            }
        }

        .product-item-name {
            margin-top: 0;
        }

        .product-item-details {
            margin: 0 0 0 85px;
        }

        .product-item-actions {
            display: block;
            margin-top: $indent__s;
        }

        .price-box {
            display: block;
            margin: 7px 0;
        }

        .text {
            margin-right: 8px;
        }

        .counter {
            @include lib-css(color, $primary__color__lighter);
            @include lib-font-size(12);
            white-space: nowrap;
        }

        .minilist {
            .price {
                display: inline;
                padding: 0;
            }

            .weee:before {
                display: inline-block;
            }
        }
    }

    .action {
        &.delete {
            @extend .abs-remove-button-for-blocks;
            position: absolute;
            right: 0;
            top: 0;
        }
    }

    .subtitle {
        @extend .abs-no-display;
    }

    //
    //  Product images only
    //  ---------------------------------------------

    .product-items-images {
        @extend .abs-add-clearfix;
        margin-left: -$indent__xs;

        .product-item {
            @extend .abs-add-box-sizing;
            float: left;
            padding-left: $indent__xs;
        }
    }

    //
    //  Product names only
    //  ---------------------------------------------

    .product-items-names {
        .product-item {
            margin-bottom: $indent__s;
        }

        .product-item-name {
            margin: 0;
        }
    }
}

//
//  Compare Products Page
//  ---------------------------------------------

body.catalog-product-compare-index {
    .action.print {
        float: right;
        margin: 15px 0;
    }
}

.table-wrapper.comparison {
    clear: both;
    max-width: 1440px;
    margin: auto;
    // overflow-x: auto;
    .sticky-table {
        position: sticky;
    }
}

.table-comparison {
    table-layout: fixed;
    overflow: hidden;
    .product-item-name {
        font-size: 2rem;
    }

    .cell.label.remove,
    .cell.label.product {
        span {
            @extend .abs-visually-hidden;
        }
    }

    .cell.label,
    td:not(:last-child) {
        border-right: $table__border-width $table__border-style $c-background-grey;
    }

    .cell {
        padding: 24px 16px;
        width: 140px;
        background-color: $c-background-white;

        .attribute.value {
            overflow: hidden;
            width: 100%;
        }

        &.product.info,
        &.product.label,
        &.border-bottom {
            border-bottom: $table__border-width $table__border-style $c-background-grey;
        }

        &.label {
            .attribute.label {
                display: block;
                width: 100%;
                word-wrap: break-word;
                &.general-attributes {
                    color: $c-greyish-brown;
                }
            }
        }

        &.attribute {
            @include lib-font-size(16);
            img {
                height: auto;
                max-width: 100%;
            }
        }
    }

    .product-item-photo {
        display: block;
        margin: 0 auto 15px;
    }

    .product-image-photo {
        margin-left: 0;
    }

    .product-item-actions,
    .price-box,
    .product.rating,
    .product-item-name {
        display: block;
        margin: 15px 0;
        min-height: auto;
    }

    .product-addto-links {
        margin-top: 15px;

        .action.split,
        .action.toggle {
            @include lib-button-s();
        }

        .action.toggle {
            padding: 0;
        }
    }

    .cell.remove {
        padding-bottom: 0;
        padding-top: 0;
        text-align: right;

        .action.delete {
            @extend .abs-remove-button-for-blocks;
        }
    }

    .product-item-actions {
        > .actions-primary {
            + .actions-secondary {
                margin-top: $indent__s;
            }
        }
    }

    .action {
        &.tocart {
            white-space: nowrap;
        }
    }
}

.comparison.headings {
    @include lib-css(background, $page__background-color);
    left: 0;
    position: absolute;
    top: 0;
    width: auto;
    z-index: 2;
}

.block-compare {
    .block-title {
        @extend .abs-block-title;
    }

    .product-item .product-item-name {
        margin-left: 22px;
    }

    .action {
        &.delete {
            @extend .abs-remove-button-for-blocks;
            left: -6px;
            position: absolute;
            top: 0;
        }

        &.compare {
            @extend .abs-revert-secondary-color;
        }
    }

    .counter {
        @extend .abs-block-items-counter;
    }

    .actions-toolbar {
        margin: 17px 0 0;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__lx) {
    .catalog-product_compare-index {
        .page-main {
           margin-top: 14.4rem;
        }
    }
    .cms-alt-ik_sectors {
     /*   .image-container {
            .page-title-wrapper {
                padding: 0 3rem;
                box-sizing: border-box;
                width: 100%;
            }
        }*/
        .sector {
            &.limpieza {
                padding-bottom: 17rem;
                .info {
                    padding-left: 3rem;
                }
            }
            &.virus-epidemias,
            &.alimentario {
                padding-bottom: 17rem;
                .info {
                    padding-left: 3rem;
                }
            }
            &.pest-control {
                .info {
                    .characteristics {
                        align-self: center;
                    }
                }
            }
            &.industria {
                .info {
                    .characteristics {
                        align-self: center;
                    }
                }
            }
            &.automocion {
                justify-content: space-between;
                .info {
                    .characteristics {
                        align-self: center;
                    }
                }
            }
            &.construccion {
                justify-content: space-between;
                .info {
                    .characteristics {
                        align-self: center;
                    }
                }
            }
            .info {
                h2 {
                    font-size: 3rem;
                }
            }
        }
    }
}

@include max-screen($screen__l) {
    .cms-alt-ik_sectors {
        .image-container {
            display: block;
            height: 460px;
            width: 100%;
            position: relative;
            .img {
                height: 460px;
                width: 100%;
                display: block;
                img {
                    @include object-fit(cover);
                    height: 100%;
                }
            }
            .page-title-wrapper {
                padding: 0 3rem;
                box-sizing: border-box;
                width: 100%;
                margin-top: 20rem;
            }
        }
    }
}

@include max-screen($screen__ml) {
    .cms-alt-ik_sectors {
        .sector {
            &.construccion,
            &.automocion {
                padding-left: 3rem;
                padding-right: 3rem;
            }
            &.virus-epidemias,
            &.limpieza,
            &.alimentario {
                align-items: center;
            }
        }
    }
    .spare-parts-info{
        flex-direction: column;
        align-items: inherit;
        .spare-parts-image{
            flex-basis: 100%;
            padding-right: 0;
            padding-bottom: $indent__m;
        }
        .spare-parts-table{
            flex-basis: 100%;
            padding-left: 0;
        }
    }
}

@include max-screen($screen__mml) {
    .table-comparison {
        width: auto;
        .cell {
            min-width: 200px;
            max-width: 200px;
        }
    }
}

@include max-screen($screen__m) {
    .catalog-product_compare-index {
        .page-main {
           margin-top: 9.8rem;
        }
    }
    .catalog-product-view {
        .column.main {
            @include lib-vendor-prefix-display(block);
            .grid{
                padding: $indent__m;
            }
            .anchor-content {
                &:not(.empty){
                    cursor: pointer;
                }
                .anchor-title {
                    color: $c-text-white;
                    position: relative;
                    &:after {
                        content: url(../images/icon-arrow-ik.svg);
                        position: absolute;
                        display: inline-block;
                        right: -20px;
                        top: 50%;
                        -webkit-transform: translateY(-50%);
                        transform: translateY(-50%);
                        width: 16px;
                        transition: transform 0.5s ease-in;
                    }
                }
                &.active {
                    .anchor-title:after {
                        transform: translateY(-50%) rotate(-180deg);
                        transition: transform 0.5s ease-in;
                    }
                    .anchors-wrapper {
                        display: block;
                        margin-top: 4rem;
                        ul {
                          list-style: none;
                          display: inline-block;
                          text-align: left;
                          margin: 0;
                          padding: 0;
                          li {
                            margin-bottom: 3rem;
                            a {
                              font-weight: normal;
                            }
                          }
                        }
                    }
                }
                .anchors-wrapper {
                    display: none;
                    ul {
                        display: inline-block;
                        margin: 0;
                        padding: 0;
                        li {
                            margin-bottom: 3rem;
                            a {
                                font-weight: normal;
                            }
                        }
                    }
                }
            }
        }

        .product-view-section{
            &.anchored,
            .product-view-section-anchored-container{
                padding: 0 $indent__m;
            }
            .title{
                h3{
                    margin: 5.6rem 0 $indent__xl;
                }
            }
        }
        .usage_area {
            .slider-and-video {
                margin-bottom: 5.6rem;
            }
        }
        .technical_info {
            margin-top: 5.6rem;
            &.usage-area-exists{
                margin-top: -9.2rem;
                .product-view-section-anchored-container{
                    padding-top: 9.6rem;
                    padding-bottom: 6.2rem;
                }
            }
            .product-view-section-anchored-container{
                padding-top: 0.6rem;
                padding-bottom: 6.2rem;
            }
            .product-view-section-content{
                padding: 0;
                @include lib-vendor-prefix-flex-direction(column);
                .links {
                    @include lib-vendor-prefix-flex-direction(row);
                    padding: 4rem 0 0;
                }
            }
        }

        .product.media {
            @include lib-vendor-prefix-order(-1);
        }

        #magicimg-wrapper {
            .desktop {
                display: none;
            }
            .mobile {
                display: block;
            }
        }
    }

    .product-info-main .box-tocart {
        .actions {
            .action.tocart {
                @extend .abs-button-responsive-smaller;
            }
        }
    }

    .block.related {
        .action.select {
            display: block;
            margin: $indent__xs 0;
        }
    }

    .compare,
    .product-addto-links .action.tocompare,
    .product-item-actions .actions-secondary > a,
    [class*='block-compare'] {
        display: none;
    }

    .cms-alt-ik_sectors {
        font-size: 1.4rem;
        .sector {
            &.industria,
            &.pest-control {
                .info {
                    padding: 4rem 2rem 0;
                }
            }
        }
        .image-container {
            .page-title-wrapper {
                margin-top: 15rem;
            }
        }
    }

    .cms-manuales-instruccion-uso,
    .cms-descarga-catalogo {
        .top-banner {
            .top-title {
                font-size: $font-size__xl;
            }

            .image-title {
                font-weight: $font-weight__regular !important;
                font-size: 1.6rem !important;
            }
        }

        .products-handbooks {
            .product-handbook {
                width: 100%;
            }
        }
    }
    .spare-parts-info{
        padding: 0 $indent__m $indent__m;
    }
}

@include max-screen($screen__sx) {
    .catalog-product-view {
        .technical_info {
            .product-view-section-content{
                .links {
                    @include lib-vendor-prefix-flex-direction(column);
                    .catalogo-instrucciones {
                        margin-top: 2rem;
                    }
                }
            }
        }
    }
    body.cms-alt-ik_sectors {
        .sector {
            &.construccion,
            &.automocion {
                margin: 5.6rem 0;
                padding: 0;
                .info {
                    padding: 3rem 5rem 0;
                    display: block;
                    text-align: center;
                    .title {
                        margin-bottom: 3rem;
                    }
                    .characteristics {
                        text-align: left;
                        padding-left: 0;
                    }
                }
            }
            &.virus-epidemias,
            &.limpieza,
            &.alimentario {
                padding: 5.6rem 5rem;
                flex-direction: column;
                .info {
                    padding: 3rem 0 0;
                    text-align: center;
                    .title {
                        margin-bottom: 3rem;
                    }
                    .characteristics {
                        text-align: left;
                        padding-left: 0;
                    }
                }
            }
            &.industria,
            &.pest-control {
                margin: 0;
                padding: 0;
                max-width: 100%;
                display: block;
                margin-bottom: $indent__xl;
                .info {
                    padding: 3rem 5rem 0;
                    display: block;
                    text-align: center;
                    .title {
                        margin-bottom: 3rem;
                    }
                    .characteristics {
                        text-align: left;
                        padding-left: 0;
                    }
                }
            }
            .info {
                svg {
                    width: 38px;
                    height: 38px;
                }
                h2 {
                    font-size: 3.6rem;
                }
            }
        }
        .image-container {
            display: block;
            height: 484px;
            width: 100%;
            position: relative;
            .img {
                height: 484px;
                width: 100%;
                display: block;
                img {
                    @include object-fit(cover);
                    height: 100%;
                }
            }
            .page-title-wrapper {
                padding: 0 3rem;
                box-sizing: border-box;
                width: 100%;
                margin-top: 20rem;
                .image-title {
                    font-size: 3.2rem;
                }
                .image-subtitle {
                    padding: 0 2rem;
                }
                .action.primary {
                    width: 100%;
                }
            }
        }
    }

    #included-pieces-table{
        .ipt-qty{
            flex-basis: 30px;
        }
    }
}

@include max-screen($screen__s) {
    .cms-manuales-instruccion-uso,
    .cms-descarga-catalogo {
        .products-manual-search {
            .search-wrapper {
                width: 100%;
            }
        }
    }
    .cms-alt-ik_sectors {
        .sector {
            &.virus-epidemias,
            &.limpieza,
            &.alimentario {
                padding-top: 7rem;
                padding-bottom: 14rem;
            }
        }
    }
    .category-cms {
        background-color: $c-background-grey;
        .subcategory-description-wrapper {
            text-align: left;
        }
        .compatibility {
            padding: 30px 40px;
        }
    }
    #spare-parts-table{
        .spt-number {
            flex-basis: 40px;
        }
        .spt-name {
            flex-basis: 50%;
        }
        .spt-qty {
            flex-basis: 25px;
        }
    }
    .catalog-product-view{
        .product-view-section{
            .title{
                h3{
                    @include lib-heading(h3);
                    color: $c-text-black;
                    margin: $indent__xl 0 $indent__m;
                }
            }
        }
        .usage_area {
            .slider-and-video {
                margin-bottom: $indent__xl;
            }
        }
        .technical_info {
            margin-top: $indent__xl;
            &.usage-area-exists{
                margin-top: -5rem;
                .product-view-section-anchored-container {
                    padding-top: 5rem;
                    padding-bottom: 4rem;
                }
            }
            .product-view-section-anchored-container {
                padding-top: 0;
                padding-bottom: 4rem;
            }
        }
    }
}

@include max-screen($screen__xs) {
    .catalog-product-view {
        .column.main {
            .grid {
                grid-template-areas: "description" "image" "info";
                grid-template-columns: 1fr;
                -ms-grid-columns: 1fr;
                padding-top: 0;

                .product {
                    &.family {
                        display: none;
                    }

                    &.info {
                        -ms-grid-row: 1;
                        -ms-grid-column: 1;
                    }

                    &.image {
                        -ms-grid-row: 2;
                        -ms-grid-column: 1;
                        margin-top: 8px;
                        margin-bottom: 8px;
                    }

                    &.used {
                        -ms-grid-row: 3;
                        -ms-grid-column: 1;
                    }
                }
            }
        }

        .accessories {
            .product-view-section-content{
                .accessory {
                    grid-template-areas: "photo text" "photo button";
                    grid-template-columns: 8rem auto;
                    grid-template-rows: 1fr 1fr;
                    -ms-grid-columns: 8rem auto;
                    -ms-grid-rows: 1fr 1fr;
                    align-items: center;
                    padding: 1rem;

                    a {
                        width: 150px;
                        padding: 16px 20px;
                        margin-left: 10px;
                        -ms-grid-column: 2;
                        -ms-grid-row: 2;
                        -ms-grid-row-align: center;
                    }

                    span {
                        color: $c-text-brown-grey;
                        font-weight: bold;
                        max-width: 22rem;
                        margin: 10px;
                        -ms-grid-column: 2;
                        -ms-grid-row: 1;
                        -ms-grid-row-align: center;
                    }

                    img {
                        -ms-grid-column: 1;
                        -ms-grid-row: 1;
                        -ms-grid-column-span: 2;
                        -ms-grid-row-align: center;
                    }
                }
            }
        }

        .anchor-content {
            .to-element-link {
                right: 0.4rem;
            }
        }
    }
    .cms-manuales-instruccion-uso,
    .cms-descarga-catalogo {
        .products-handbooks {
            .product-handbook {
                grid-template-areas: "photo text" "photo button";
                grid-template-columns: 8rem auto;
                grid-template-rows: 1fr auto;
                -ms-grid-columns: 8rem auto;
                -ms-grid-rows: 1fr auto;
                align-items: center;
                padding: 1rem;

                .text {
                    -ms-grid-column: 2;
                    -ms-grid-row: 1;
                    -ms-grid-row-align: center;
                }

                img {
                    -ms-grid-column: 1;
                    -ms-grid-row: 1;
                    -ms-grid-column-span: 2;
                    -ms-grid-row-align: center;
                }

                a {
                    width: 150px;
                    padding: 6px 20px;
                    -ms-grid-column: 2;
                    -ms-grid-row: 2;
                    -ms-grid-row-align: center;
                }
            }
        }

        .products-manual-search {
            .search-wrapper {
                min-width: 20rem;
            }
        }
    }
    .catalog-category-view {
        .breadcrumbs {
            padding-left: 24px;
        }

        .category-cms {
            background-color: $c-background-grey;

            .subcategory-description-wrapper {
                text-align: left;
            }

            .compatibility {
                text-align: center;
            }
        }
    }
    .cms-manuales-instruccion-uso {
        .top-banner {
            .image-title {
                margin-top: 28.5rem !important;
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__xs) {
    .catalog-product-view {
        .column.main .grid {
            grid-template-areas: "description image" "info image" "family family";

            grid-template-columns: 1fr 1fr;
            -ms-grid-columns: 1fr 1fr;

            .product {
                &.used {
                    margin-top: 5rem;
                    -ms-grid-row: 2;
                    -ms-grid-column: 1;
                }

                &.family {
                    -ms-grid-row: 3;
                    -ms-grid-column: 1;
                    -ms-grid-column-span: 2;
                }

                &.info {
                    -ms-grid-row: 1;
                    -ms-grid-column: 1;
                }

                &.image {
                    -ms-grid-row: 1;
                    -ms-grid-column: 2;
                    -ms-grid-row-span: 2;
                }
            }
        }

        .accessories {
            .product-view-section-content{
                .accessory {
                    grid-template-areas: "photo text button";
                    grid-template-columns: .5fr 1.5fr 1fr;
                    -ms-grid-columns: .5fr 1.5fr 1fr;
                    align-items: center;

                    a {
                        -ms-grid-column: 3;
                        -ms-grid-row: 1;
                    }

                    span {
                        -ms-grid-column: 2;
                        -ms-grid-row: 1;
                    }

                    img {
                        -ms-grid-column: 1;
                        -ms-grid-row: 1;
                    }
                }
            }
        }
    }
    .cms-manuales-instruccion-uso,
    .cms-descarga-catalogo {
        .products-handbooks {
            .product-handbook {
                grid-template-areas: "photo text button";
                grid-template-columns: .5fr 1.5fr 1fr;
                -ms-grid-columns: .5fr 1.5fr 1fr;
                align-items: center;

                .text {
                    -ms-grid-column: 2;
                    -ms-grid-row: 1;
                }

                img {
                    -ms-grid-column: 1;
                    -ms-grid-row: 1;
                }

                a {
                    -ms-grid-column: 3;
                    -ms-grid-row: 1;
                }
            }
        }
    }
}

@include min-screen($screen__m) {
    .catalog-product-view {
        .column.main .grid {
            // @include lib-vendor-prefix-display(flex);
            // @include lib-vendor-prefix-flex-direction(column);
            grid-template-areas: "description image info" "none1 family none2";

            grid-template-columns: 1fr 1.5fr 1fr;
            -ms-grid-columns: 1fr 1.5fr 1fr;
            max-width: 1600px;
            margin: auto;
            box-sizing: border-box;

            .product {
                &.image {
                    display: block;
                    margin: auto;
                    -ms-grid-row: 1;
                    -ms-grid-column: 2;
                    -ms-grid-column-span: 1;
                    -ms-grid-row-span: 1;
                }

                &.used {
                    margin-top: 5rem;
                    -ms-grid-row: 1;
                    -ms-grid-column: 3;
                }

                &.family {
                    -ms-grid-row: 2;
                    -ms-grid-column: 2;
                    -ms-grid-column-span: 1;
                }

                &.info {
                    -ms-grid-row: 1;
                    -ms-grid-column: 1;
                }
            }
        }

        .functional_info {
            .magic-characteristics {
                display: none;
            }
        }

        .technical_info {
            .information {
                flex: 1 1 70%;
            }

            .links {
                flex: 0 0 30%;
            }
        }

        .anchor-content {
            .anchor-title {
                display: none;
            }

            ul {
                margin-bottom: 0;

                li {
                    margin-bottom: 0;
                    display: inline-block;
                }
            }

            .anchor {
                padding: 0 1rem;
            }
        }
    }
    .product-info-main,
    .product-options-bottom {
        .box-tocart {
            display: table;

            .field.qty {
                display: table-cell;
            }

            .actions {
                display: table-cell;
                padding-top: $indent__m;
                text-align: center;
                vertical-align: bottom;
            }
        }
    }

    .product-info-main {
        float: right;
        .page-title-wrapper {
            .page-title {
                margin-top: -13px;
            }
        }
    }

    .sidebar {
        .product-items {
            .product-item-info {
                .product-item-photo {
                    float: left;
                    left: auto;
                    margin: 0 $indent__s $indent__s 0;
                    position: relative;
                    top: auto;
                }
            }

            .product-item-details {
                margin: 0;
            }

            .product-item-actions {
                clear: left;
            }
        }
    }

    .product-add-form {
        @extend .abs-revert-field-type-desktop;
    }

    .page-layout-2columns-left,
    .page-layout-2columns-right,
    .page-layout-3columns {
        .product-info-main {
            width: 48%;
        }

        .product.media {
            width: 50%;
        }
    }
}

@include min-screen($screen__l) {
    .cms-manuales-instruccion-uso,
    .cms-alt-ik_sectors {
        .image-container {
            overflow-x: hidden;
            overflow-y: hidden;
            .img {
                height: 868px;
                width: 100%;
                display: block;

                img {
                    object-fit: cover;
                    font-family: "object-fit: cover";
                    height: 100%;
                    width: 100%;
                }
            }

            display: block;
            height: 868px;
            width: 100%;
            position: relative;

            .page-title-wrapper {
                .image-subtitle
                {
                    margin: auto;
                    width: 90rem;
                    font-size: $font-size__l;
                }
                top: 50%;
                transform: translate(-50%, -50%);

                a {
                    margin-top: 3rem;
                }
            }
        }
    }
}

@include min-screen($screen__xl) {
    .sidebar {
        .product-items {
            .product-item-info {
                .product-item-photo {
                    float: none;
                    left: 0;
                    margin: 0;
                    position: absolute;
                    top: 0;
                }
            }

            .product-item-details {
                margin-left: 85px;
            }
        }
    }
    .cms-alt-ik_sectors {
        .automocion,
        .construccion {
            .info{
                margin-left: 24.3rem;
                margin-right: 24.3rem;
            }
        }

    }
}
