.interactive-product {
    display: flex;
    margin: 0 auto $indent__xll;
    padding: $indent__xl 3.4%;
    align-items: flex-start;
    justify-content: space-between;
    max-width: $screen__xxl;
    box-sizing: border-box;
    &__svg-content {
        flex-basis: 65%;
        margin-right: $indent__xll;
        svg {
            width: 100%;
            max-width: 100%;
            max-height: 80vh;
            g {
                /*transition: fill .5s;*/
                cursor: pointer;
            }

            path.lines {
                fill: $c-text-black !important;
            }

            g:not(.selector) {
                cursor: help;
            }
        }
    }
    &__table-wrapper {
        flex-basis: 35%;
        border: 1px solid $c-background-black;
        & > .element {
            padding-left: $indent__ml;
            padding-right: $indent__ml;
        }
    }
    .info-table {
        &__flex-header {
            background-color: $c-background-black;
            height: 50px;
            color: white;
            text-transform: uppercase;
            font-weight: 600;
        }
    }
    .element {
        width: 100%;
        box-sizing: border-box;
        border-top: 1px solid $c-background-black;
        &.grouped {
            cursor: pointer;
        }
        &.simple {
            cursor: text;
        }
        .childs {
            display: none;
            width: 100%;
            padding-left: $indent__m;
        }
        &__info-wrapper {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            padding-top: $indent__xss;
            padding-bottom: $indent__xss;
        }
        &__id {
            flex-basis: 10%;
            min-width: 50px;
            margin-right: $indent__ml;
        }
        &__code {
            flex-basis: 35%;
            flex-grow: 1;
            margin-right: $indent__ml;
        }
        &__input-qty {
            flex-basis: 10%;
            margin-right: $indent__ml;
            min-width: 50px;
            input {
                text-align: center;
            }
        }
        &__actions {
            min-width: 100px;
            flex-basis: 20%;
            &.header {
                font-size: 0;
            }
            .add {
                min-width: 0;
            }
        }
    }
}

@include max-screen($screen__l) {
    .interactive-product {
        flex-direction: column;
        align-items: center;
        &__svg-content {
            width: 100%;
            margin-right: 0;
        }
        &__table-wrapper {
            margin-top: $indent__xxxl;
            flex-grow: 1;
            width: 100%;
        }
    }
}

//Temporaly hide buy options
.interactive-product.disable-buying {
    &__svg-content {
        flex-grow: 1;
    }
    &__table-wrapper {
        flex-basis: 25%;
    }
    .element {
        &__input-qty {
            display: none !important;
        }
        &__actions {
            display: none !important;
        }
        &__info-wrapper {
            height: 48px;
        }
    }
}
